import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

/**
 * Función para formatear el monto a formato de moneda
 * 
 * @param {number} amount - El monto a formatear.
 * @returns {string} - Monto formateado como string en formato de moneda.
 */
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(amount);
};

/**
 * PendingBalanceCard Component
 * 
 * Este componente muestra el saldo pendiente del paciente si es mayor que cero.
 * 
 * @param {number} amount - El monto del saldo pendiente.
 */
const PendingBalanceCard = ({ amount }) => {
  if (amount <= 0) return null; // Si no hay saldo pendiente, no se renderiza nada.

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Card variant="outlined" sx={{ backgroundColor: 'lightyellow', borderColor: 'orange' }}>
        <CardContent>
          <Typography variant="h6" color="textSecondary">
            Pending Balance: {formatCurrency(amount)}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PendingBalanceCard;

import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import api from '../api';
import dayjs from 'dayjs';

const CashflowPage = () => {
  const currentMonthStart = dayjs().startOf('month').toDate();
  const currentMonthEnd = dayjs().endOf('month').toDate();

  const [startDate, setStartDate] = useState(currentMonthStart);
  const [endDate, setEndDate] = useState(currentMonthEnd);
  const [data, setData] = useState({
    operatingActivities: 0,
    patientPayments: 0,
    providerPayments: 0,
    medicalSupplies: 0,
    salariesAndWages: 0,
    rent: 0,
    utilities: 0,
    otherOperatingExpenses: 0,
    marketingAndAdvertising: 0,
    softwareAndSubscriptions: 0,
    administrativeExpenses: 0,
    otherExpenses: 0,
    taxes: 0,
    insuranceExpense: 0,
    netCashflowOps: 0,
    financingActivities: 0,
    loansReceived: 0,
    shareCapital: 0,
    loanPaid: 0,
    interestPaid: 0,
    netCashflowFinancing: 0,
    netIncrease: 0,
    cashAtBeginning: 1000,
    cashAtEnd: 1000,
    ppeAcquisitions: 0, // Nuevo estado para PPE
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.post('/financialTransactions/cashflow', {
          startDate: dayjs(startDate).toISOString(),
          endDate: dayjs(endDate).toISOString()
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching cashflow data:', error);
      }
    };

    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate]);

  const formatAmount = (amount) => {
    const formatted = amount.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return amount < 0 ? `($${formatted})` : `$${formatted}`;
  };

  const renderRow = (category, amount, isIndented = false, isHeader = false, isSubtotal = false) => (
    <TableRow key={category} sx={{ backgroundColor: isSubtotal ? '#f5f5f5' : 'transparent', fontWeight: isSubtotal ? 'bold' : 'normal' }}>
      <TableCell sx={{ paddingLeft: isIndented ? 4 : isHeader || isSubtotal ? 2 : 0, fontWeight: isHeader || isSubtotal ? 'bold' : 'normal' }}>{category}</TableCell>
      <TableCell sx={{ fontWeight: isSubtotal ? 'bold' : 'normal' }}>{isHeader ? '' : formatAmount(amount)}</TableCell>
    </TableRow>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <Typography variant="h2">Cashflow</Typography>
        <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 2 }}>
          <DatePicker
            label="Start Date"
            value={dayjs(startDate)}
            onChange={(newValue) => setStartDate(newValue.toDate())}
            slotProps={{ textField: { variant: 'outlined' } }}
          />
          <DatePicker
            label="End Date"
            value={dayjs(endDate)}
            onChange={(newValue) => setEndDate(newValue.toDate())}
            slotProps={{ textField: { variant: 'outlined' } }}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Category</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderRow('Operating Activities', 0, false, true)}
                {renderRow('Patient Payments', data.patientPayments, true)}
                {renderRow('Provider Payments', -data.providerPayments, true)}
                {renderRow('Medical Supplies', -data.medicalSupplies, true)}
                {renderRow('Salaries and Wages', -data.salariesAndWages, true)}
                {renderRow('Rent', -data.rent, true)}
                {renderRow('Utilities', -data.utilities, true)}
                {renderRow('Other Operating Expenses', -data.otherOperatingExpenses, true)}
                {renderRow('Marketing and Advertising', -data.marketingAndAdvertising, true)}
                {renderRow('Software and Subscriptions', -data.softwareAndSubscriptions, true)}
                {renderRow('Administrative Expenses', -data.administrativeExpenses, true)}
                {renderRow('Other Expenses', -data.otherExpenses, true)}
                {renderRow('Taxes', -data.taxes, true)}
                {renderRow('Insurance Expense', -data.insuranceExpense, true)}
                {renderRow('Net Cashflow from Operating Activities', data.netCashflowOps, false, false, true)}

                {renderRow('Investing Activities', 0, false, true)}
                {renderRow('PPE Acquisitions', -data.ppeAcquisitions, true)}  {/* Agregar PPE aquí */}
                {renderRow('Net Cashflow from Investing Activities', -data.ppeAcquisitions, false, false, true)} {/* Mostrar PPE aquí */}

                {renderRow('Financing Activities', 0, false, true)}
                {renderRow('Loans Received', data.loansReceived, true)}
                {renderRow('Share Capital', data.shareCapital, true)}
                {renderRow('Loan Paid', -data.loanPaid, true)}
                {renderRow('Interest Paid', -data.interestPaid, true)}
                {renderRow('Net Cashflow from Financing Activities', data.netCashflowFinancing, false, false, true)}

                {renderRow('Net Increase', data.netIncrease, false, false, true)}
                {renderRow('Cash at Beginning', data.cashAtBeginning, false, false, true)}
                {renderRow('Cash at End', data.cashAtEnd, false, false, true)}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </LocalizationProvider>
  );
};

export default CashflowPage;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api';
import { Container, Typography, Grid, IconButton, CircularProgress, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LeadDetailsCard from '../components/LeadDetailsCard';
import LeadConversationHistory from '../components/LeadConversationHistory';
import LeadAppointmentsList from '../components/LeadAppointmentsList';

const LeadDetailPage = () => {
  const { leadId } = useParams();
  const navigate = useNavigate();
  const [lead, setLead] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeadDetails = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/leads/${leadId}`);
        setLead(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching lead details:', error);
        setError('Error al cargar los detalles del lead. Por favor, intente de nuevo.');
      } finally {
        setLoading(false);
      }
    };

    fetchLeadDetails();
  }, [leadId]);

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {/* Encabezado de la página */}
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <IconButton onClick={() => navigate('/leads')} aria-label="volver a leads">
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h4">{`${lead.firstName} ${lead.lastName}`}</Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Detalles del lead y lista de citas */}
        <Grid item xs={12} md={6}>
          <Box mb={3}>
            <LeadDetailsCard lead={lead} />
          </Box>
          <Box mt={3}>
            <LeadAppointmentsList leadId={leadId} />
          </Box>
        </Grid>

        {/* Historial de conversaciones */}
        <Grid item xs={12} md={6}>
          <LeadConversationHistory leadId={leadId} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default LeadDetailPage;

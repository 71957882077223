import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import api from '../api';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [clinic, setClinic] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [whatsappConfig, setWhatsappConfig] = useState(null);  // Nueva variable de estado para WhatsApp
  const [openAIConfig, setOpenAIConfig] = useState(null);  // Nueva variable de estado para OpenAI
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await api.get('/auth/me');
      setUser(response.data.user);
      setClinic(response.data.clinic);
      setWhatsappConfig(response.data.clinic.whatsappConfig);  // Configuración de WhatsApp
      setOpenAIConfig(response.data.clinic.openAIConfig);  // Configuración de OpenAI
      setIsAuthenticated(true);
      checkGoogleConnection();
    } catch (error) {
      console.error('Error fetching user data:', error);
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const checkGoogleConnection = useCallback(async () => {
    try {
      const response = await api.get('/auth/google/status');
      setIsGoogleConnected(response.data.isConnected);
    } catch (error) {
      console.error('Error checking Google connection:', error);
      setIsGoogleConnected(false);
    }
  }, []);

  const checkAuthStatus = useCallback(async () => {
    if (isAuthenticated) return; // Si ya está autenticado, no hacer nada
    const token = localStorage.getItem('token');
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      await fetchUserData();
    } else {
      setIsAuthenticated(false);
      setUser(null);
      setClinic(null);
      setLoading(false);
    }
  }, [isAuthenticated, fetchUserData]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      fetchUserData();

      // Configurar intervalo para renovar el token
      const renewInterval = setInterval(() => {
        renewToken();
      }, 6 * 60 * 60 * 1000); // Renovar cada 6 horas

      return () => clearInterval(renewInterval);
    } else {
      setLoading(false);
    }
  }, [fetchUserData]);

  const login = async ({ token, clinicId, role }) => {
    localStorage.setItem('token', token);
    localStorage.setItem('clinicId', clinicId);
    localStorage.setItem('role', role);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    await fetchUserData();
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('clinicId');
    localStorage.removeItem('role');
    delete api.defaults.headers.common['Authorization'];
    setIsAuthenticated(false);
    setUser(null);
    setClinic(null);
    setWhatsappConfig(null);
    setOpenAIConfig(null);
    setIsGoogleConnected(false);
  };

  const handleGoogleAuthSuccess = useCallback(() => {
    setIsGoogleConnected(true);
    // Aquí puedes añadir lógica adicional si es necesario
  }, []);

  const handleGoogleAuthError = useCallback(() => {
    setIsGoogleConnected(false);
    // Aquí puedes añadir lógica para manejar el error
  }, []);

  const renewToken = async () => {
    try {
      const response = await api.post('/auth/renew-token');
      const { token } = response.data;
      localStorage.setItem('token', token);
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } catch (error) {
      console.error('Error renewing token:', error);
      logout();
    }
  };

  const updateClinic = useCallback((updatedClinic) => {
    setClinic(prevClinic => ({ ...prevClinic, ...updatedClinic }));
  }, []);

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      login, 
      logout, 
      clinic,
      user,
      whatsappConfig,
      openAIConfig,
      loading,
      isGoogleConnected,
      handleGoogleAuthSuccess,
      handleGoogleAuthError,
      checkGoogleConnection,
      checkAuthStatus,
      updateClinic
    }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import api from '../api';

function SearchModal({ open, onClose }) {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({ patients: [], providers: [] });
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const navigate = useNavigate();
  const { clinic } = useAuth();
  const inputRef = useRef(null);

  useEffect(() => {
    if (open) {
      setQuery('');
      setResults({ patients: [], providers: [] });
      setSelectedIndex(-1);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100); // Aseguramos que el modal esté renderizado antes de enfocar
    }
  }, [open]);

  const handleSearch = async (event) => {
    const value = event.target.value;
    setQuery(value);

    if (value.trim() === '') {
      setResults({ patients: [], providers: [] });
      setSelectedIndex(-1);
      return;
    }

    setLoading(true);
    try {
      const response = await api.get(`/search?q=${value}&clinic=${clinic._id}`);
      setResults(response.data);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleResultClick = (type, id) => {
    if (type === 'patient') {
      navigate(`/patients/${id}`);
    } else if (type === 'provider') {
      navigate(`/settings/providers/${id}`);
    }
    onClose();
  };

  const handleKeyDown = (event) => {
    const totalResults = results.patients.length + results.providers.length;

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex < totalResults - 1 ? prevIndex + 1 : 0
      );
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : totalResults - 1
      );
    } else if (event.key === 'Enter' && selectedIndex >= 0) {
      event.preventDefault();
      const totalPatients = results.patients.length;
      if (selectedIndex < totalPatients) {
        handleResultClick('patient', results.patients[selectedIndex]._id);
      } else {
        const providerIndex = selectedIndex - totalPatients;
        handleResultClick('provider', results.providers[providerIndex]._id);
      }
    }
  };

  useEffect(() => {
    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, selectedIndex, results]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50vw',
          maxHeight: '70vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: 'auto',
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>Buscar</Typography>
        <TextField
          value={query}
          onChange={handleSearch}
          placeholder="Buscar pacientes o proveedores..."
          variant="outlined"
          fullWidth
          inputRef={inputRef} // Asignar la referencia para el enfoque
        />
        {loading && <CircularProgress size={24} sx={{ mt: 2 }} />}
        <List>
          {results.patients.length > 0 && (
            <>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>Pacientes</Typography>
              {results.patients.map((patient, index) => (
                <ListItem
                  button
                  key={patient._id}
                  selected={selectedIndex === index}
                  onClick={() => handleResultClick('patient', patient._id)}
                >
                  <ListItemText primary={`${patient.firstName} ${patient.lastName}`} secondary={patient.phone} />
                </ListItem>
              ))}
            </>
          )}
          {results.providers.length > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1">Proveedores</Typography>
              {results.providers.map((provider, index) => (
                <ListItem
                  button
                  key={provider._id}
                  selected={selectedIndex === index + results.patients.length}
                  onClick={() => handleResultClick('provider', provider._id)}
                >
                  <ListItemText primary={provider.name} secondary={provider.phone} />
                </ListItem>
              ))}
            </>
          )}
        </List>
      </Box>
    </Modal>
  );
}

export default SearchModal;

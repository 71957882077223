// src/components/AddProviderModal.js
import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField, 
  Grid,
  Typography,
  Autocomplete
} from '@mui/material';
import api from '../api';

const AddProviderModal = ({ open, onClose, onProviderAdded }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    documentType: '',
    documentNumber: '',
    purchaseOrderPreference: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleAutocompleteChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : "This field is required.";
    tempErrors.email = formData.email ? "" : "This field is required.";
    tempErrors.phone = formData.phone ? "" : "This field is required.";
    tempErrors.address = formData.address ? "" : "This field is required.";
    tempErrors.documentType = formData.documentType ? "" : "This field is required.";
    tempErrors.documentNumber = formData.documentNumber ? "" : "This field is required.";
    tempErrors.purchaseOrderPreference = formData.purchaseOrderPreference ? "" : "This field is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = async () => {
    if (validate()) {
      try {
        const response = await api.post('/providers', formData);
        onProviderAdded(response.data);
        onClose();
      } catch (error) {
        console.error('Error adding provider:', error);
      }
    }
  };

  const documentTypes = [
    'NIT', 'CC', 'DNI', 'Passport', 'Driver License', 'Tax ID', 'Social Security Number', 'Other'
  ];

  const purchaseOrderPreferences = [
    'WhatsApp', 'Email', 'Other'
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6" component="div">
          Add New Provider
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Provider Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              error={!!errors.name}
              helperText={errors.name}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              error={!!errors.email}
              helperText={errors.email}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              fullWidth
              error={!!errors.phone}
              helperText={errors.phone}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              fullWidth
              error={!!errors.address}
              helperText={errors.address}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={documentTypes}
              value={formData.documentType}
              onChange={(event, newValue) => {
                handleAutocompleteChange('documentType', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Document Type"
                  error={!!errors.documentType}
                  helperText={errors.documentType}
                  fullWidth
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Document Number"
              name="documentNumber"
              value={formData.documentNumber}
              onChange={handleChange}
              fullWidth
              error={!!errors.documentNumber}
              helperText={errors.documentNumber}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={purchaseOrderPreferences}
              value={formData.purchaseOrderPreference}
              onChange={(event, newValue) => {
                handleAutocompleteChange('purchaseOrderPreference', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Purchase Order Preference"
                  error={!!errors.purchaseOrderPreference}
                  helperText={errors.purchaseOrderPreference}
                  fullWidth
                  margin="normal"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Save Provider
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProviderModal;
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  InputAdornment,
  MenuItem
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import api from '../api';
import { useAuth } from '../contexts/AuthContext';

function PurchaseOrderDrawer({ orderId, onClose, onOrderUpdated }) {
  const [order, setOrder] = useState(null);
  const [receivedProducts, setReceivedProducts] = useState([]);
  const [pendingProducts, setPendingProducts] = useState([]);
  const [paymentAmount, setPaymentAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentDate, setPaymentDate] = useState(dayjs());
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { clinic } = useAuth();

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails();
      fetchAccounts();
    } else {
      setLoading(false);
      setError('No order ID provided');
    }
  }, [orderId]);

  const fetchOrderDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await api.get(`/providerpurchases/${orderId}?clinicId=${clinic._id}`);
      const orderData = response.data;
      setOrder(orderData);
      
      setReceivedProducts(orderData.receivedProducts);

      // Modificación aquí: Calcula productos pendientes
      const pending = orderData.products.filter(p => {
        const receivedProduct = orderData.receivedProducts.find(rp => rp.product === p.product._id);
        return !receivedProduct || (receivedProduct && receivedProduct.quantity < p.quantity);
      });
      setPendingProducts(pending);

    } catch (error) {
      console.error('Error fetching order details:', error);
      setError('Failed to load order details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const fetchAccounts = async () => {
    try {
      const response = await api.get('/accounts', {
        params: {
          clinicId: clinic._id,
          codes: '1101,1102'  
        }
      });
      setAccounts(response.data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const handleReceiveProducts = async () => {
    try {
      const productsToReceive = pendingProducts
        .filter(p => p.receivedQuantity > 0)
        .map(p => ({
          product: p.product._id,
          quantity: p.receivedQuantity,
          batch: p.batch || '',
          expirationDate: p.expirationDate ? p.expirationDate.toISOString() : ''
        }));

      if (productsToReceive.length === 0) {
        throw new Error('No products to receive');
      }

      await api.post(`/providerpurchases/receive`, {
        purchaseId: order._id,
        receivedProducts: productsToReceive
      });

      fetchOrderDetails();
      onOrderUpdated();
    } catch (error) {
      console.error('Error receiving products:', error);
    }
  };

  const handlePayment = async () => {
    try {
      await api.post(`/providerPayments`, {
        provider: order.provider._id,
        amount: parseFloat(paymentAmount.replace(/[^0-9.-]+/g, "")),
        providerPurchase: order._id,
        method: paymentMethod,
        date: paymentDate.format('YYYY-MM-DD'),
        clinic: clinic._id,
        account: selectedAccount
      });
      fetchOrderDetails();
      onOrderUpdated();
    } catch (error) {
      console.error('Error making payment:', error);
    }
  };

  const formatCurrency = (amount) => {
    return `$${amount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format('DD-MMM-YY');
  };

  const handleCurrencyInput = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, '');
    if (value) {
      value = parseInt(value, 10).toLocaleString('en-US');
    }
    setPaymentAmount(value ? `$${value}` : '');
  };

  const getPaymentStatusChip = () => {
    if (!order) {
      return <Chip label="Unknown" color="default" size="small" />;
    }
    return <Chip label={order.paymentStatus} color={getPaymentStatusColor(order.paymentStatus)} size="small" />;
  };

  const getPaymentStatusColor = (status) => {
    switch (status) {
      case 'Paid':
        return 'success';
      case 'Partial Paid':
        return 'warning';
      case 'Pending':
        return 'error';
      default:
        return 'default';
    }
  };

  const handleClose = () => {
    if (order) {
      onClose(order);
    } else {
      onClose();
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">{error}</Typography>
        <Button onClick={handleClose} variant="outlined" sx={{ mt: 2 }}>
          Close
        </Button>
      </Box>
    );
  }

  if (!order) {
    return null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: 400, p: 3 }}>
        <Typography variant="h6" gutterBottom>Purchase Order Details</Typography>
        
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1" sx={{ mb: 1 }}><strong>Date:</strong> {formatDate(order.date)}</Typography>
          <Typography variant="body1" sx={{ mb: 1 }}><strong>Provider:</strong> {order.provider?.name || 'N/A'}</Typography>
          <Typography variant="body1" sx={{ mb: 1 }}><strong>Total:</strong> {formatCurrency(order.total)}</Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Order Status:</strong> <Chip label={order.status || 'N/A'} color="primary" size="small" sx={{ ml: 1 }} />
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Payment Status:</strong> {getPaymentStatusChip()}
          </Typography>
        </Box>

        <Typography variant="subtitle1" sx={{ mb: 2 }}>Ordered Products</Typography>
        <TableContainer component={Paper} sx={{ mb: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell><strong>Product</strong></TableCell>
                <TableCell align="right"><strong>Quantity</strong></TableCell>
                <TableCell align="right"><strong>Unit Price</strong></TableCell>
                <TableCell align="right"><strong>Total</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.products.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>{product.product.name}</TableCell>
                  <TableCell align="right">{product.quantity}</TableCell>
                  <TableCell align="right">{formatCurrency(product.unitPrice)}</TableCell>
                  <TableCell align="right">{formatCurrency(product.quantity * product.unitPrice)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
        <Divider sx={{ my: 2 }} />

        {receivedProducts.length > 0 && (
          <>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>Products Received</Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Product</strong></TableCell>
                    <TableCell><strong>Quantity</strong></TableCell>
                    <TableCell><strong>Batch</strong></TableCell>
                    <TableCell><strong>Expiration</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {receivedProducts.map((product, index) => (
                    <TableRow key={product._id}>
                      <TableCell>{order.products.find(p => p.product._id === product.product)?.product.name || 'Unknown'}</TableCell>
                      <TableCell>{product.quantity}</TableCell>
                      <TableCell>{product.batch}</TableCell>
                      <TableCell>{formatDate(product.expirationDate)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {pendingProducts.length > 0 && (
          <>
            <Typography variant="subtitle1" sx={{ mb: 2, mt: 2 }}>Receive Products</Typography>
            {pendingProducts.map((product, index) => (
              <Box 
                key={product.product._id} 
                sx={{ 
                  mb: 2, 
                  p: 2, 
                  border: '1px solid #e0e0e0', 
                  borderRadius: 2 
                }}
              >
                <Typography variant="body2" sx={{ mb: 2 }}>{product.product.name}</Typography>
                <TextField
                  type="number"
                  label="Quantity to receive"
                  size="small"
                  fullWidth
                  sx={{ mb: 2 }}
                  value={product.receivedQuantity || ''}
                  onChange={(e) => {
                    const receivedProduct = receivedProducts.find(rp => rp.product === product.product._id);
                    const quantityLeft = product.quantity - (receivedProduct?.quantity || 0);
                    const newPendingProducts = [...pendingProducts];
                    newPendingProducts[index].receivedQuantity = Math.min(e.target.value, quantityLeft);
                    setPendingProducts(newPendingProducts);
                  }}
                />
                <TextField
                  label="Batch"
                  size="small"
                  fullWidth
                  sx={{ mb: 2 }}
                  value={product.batch || ''}
                  onChange={(e) => {
                    const newPendingProducts = [...pendingProducts];
                    newPendingProducts[index].batch = e.target.value;
                    setPendingProducts(newPendingProducts);
                  }}
                />
                <DatePicker
                  label="Expiration Date"
                  value={product.expirationDate ? dayjs(product.expirationDate) : null}
                  onChange={(date) => {
                    const newPendingProducts = [...pendingProducts];
                    newPendingProducts[index].expirationDate = date;
                    setPendingProducts(newPendingProducts);
                  }}
                  renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                />
              </Box>
            ))}
            <Button onClick={handleReceiveProducts} variant="contained" color="primary" sx={{ mt: 2 }}>
              Receive Products
            </Button>
          </>
        )}
        <Divider sx={{ my: 2 }} />

        <Typography variant="subtitle1" sx={{ mb: 2 }}>Registered Payments</Typography>
        <TableContainer component={Paper} sx={{ mb: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell><strong>Date</strong></TableCell>
                <TableCell><strong>Amount</strong></TableCell>
                <TableCell><strong>Method</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.payments.map((payment, index) => (
                <TableRow key={index}>
                  <TableCell>{formatDate(payment.date)}</TableCell>
                  <TableCell>{formatCurrency(payment.amount)}</TableCell>
                  <TableCell>{payment.method}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {order.paymentStatus !== 'Paid' && (
          <>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>Make Payment</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label="Payment Amount"
                value={paymentAmount}
                onChange={handleCurrencyInput}
                fullWidth
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              <TextField
                select
                label="Payment Method"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                fullWidth
                size="small"
              >
                <MenuItem value="credit card">Credit Card</MenuItem>
                <MenuItem value="bank transfer">Bank Transfer</MenuItem>
                <MenuItem value="Cash">Cash</MenuItem>
              </TextField>
              <TextField
                select
                label="Account"
                value={selectedAccount}
                onChange={(e) => setSelectedAccount(e.target.value)}
                fullWidth
                size="small"
              >
                {accounts.map((account) => (
                  <MenuItem key={account._id} value={account._id}>{account.name}</MenuItem>
                ))}
              </TextField>
              <DatePicker
                label="Payment Date"
                value={paymentDate}
                onChange={(newValue) => setPaymentDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth size="small" />}
              />
            </Box>
            <Button onClick={handlePayment} variant="contained" color="primary" sx={{ mt: 2 }}>
              Make Payment
            </Button>
          </>
        )}

        <Button onClick={handleClose} variant="outlined" sx={{ mt: 2, ml: 2 }}>
          Close
        </Button>
      </Box>
    </LocalizationProvider>
  );
}

export default PurchaseOrderDrawer;

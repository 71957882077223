import React from 'react';
import { Grid, IconButton, Typography, Box } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import EventIcon from '@mui/icons-material/Event';
import HealingIcon from '@mui/icons-material/Healing';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const actions = [
  { label: 'Payment', icon: PaymentIcon, modal: 'payment' },
  { label: 'Appointment', icon: EventIcon, modal: 'appointment' },
  { label: 'Treatment', icon: HealingIcon, modal: 'treatment' },
  { label: 'Edit', icon: EditIcon, modal: 'edit' },
  { label: 'Consent', icon: DescriptionIcon, modal: 'consent' },
  { label: 'Upload Files', icon: AttachFileIcon, modal: 'uploadFiles' },
];

const PatientActions = ({ handleOpenModal }) => {
  return (
    <Grid container spacing={2} justifyContent="flex-start">
      {actions.map(({ label, icon: Icon, modal }) => (
        <Grid item key={label}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <IconButton
              onClick={() => handleOpenModal(modal)}
              aria-label={label} // Accesibilidad
              sx={{
                width: 56,
                height: 56,
                bgcolor: 'background.paper',
                boxShadow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  bgcolor: 'primary.light',
                },
              }}
            >
              <Icon fontSize="large" />
            </IconButton>
            <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
              {label}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default PatientActions;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { 
  Container, Typography, Button, Table, TableHead, TableBody, TableRow, 
  TableCell, TableContainer, Paper, IconButton, TextField, TablePagination, 
  TableSortLabel, CircularProgress, Dialog, DialogActions, DialogContent, 
  DialogContentText, DialogTitle, Box
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import AddLeadDrawer from '../components/AddLeadDrawer';

const LeadsPage = () => {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('firstName');
  const [order, setOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [leadToDelete, setLeadToDelete] = useState(null);
  const { clinic } = useAuth();
  const navigate = useNavigate();
  const [isAddLeadDrawerOpen, setIsAddLeadDrawerOpen] = useState(false);
  const [editingLead, setEditingLead] = useState(null);

  useEffect(() => {
    const fetchLeads = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/leads?clinicId=${clinic._id}`);
        const sortedLeads = response.data.sort((a, b) => 
          a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName)
        );
        setLeads(sortedLeads);
        setFilteredLeads(sortedLeads);
      } catch (error) {
        console.error('Error fetching leads:', error);
      } finally {
        setLoading(false);
      }
    };

    if (clinic) {
      fetchLeads();
    }
  }, [clinic]);

  useEffect(() => {
    const filtered = leads.filter(lead => 
      (lead.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
      (lead.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
      (lead.email?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false)
    );
    setFilteredLeads(filtered);
    setPage(0);
  }, [searchTerm, leads]);

  const handleRowClick = (id) => {
    navigate(`/leads/${id}`);
  };

  const handleDeleteLead = async (id) => {
    try {
      await api.delete(`/leads/${id}`);
      setLeads((prev) => prev.filter((lead) => lead._id !== id));
      setFilteredLeads((prev) => prev.filter((lead) => lead._id !== id));
    } catch (error) {
      console.error('Error deleting lead:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    
    const sortedLeads = [...filteredLeads].sort((a, b) => {
      if (a[property] < b[property]) return isAsc ? -1 : 1;
      if (a[property] > b[property]) return isAsc ? 1 : -1;
      return 0;
    });
    
    setFilteredLeads(sortedLeads);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDeleteClick = (event, lead) => {
    event.stopPropagation();
    setLeadToDelete(lead);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (leadToDelete) {
      handleDeleteLead(leadToDelete._id);
      setDeleteConfirmOpen(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const handleAddLeadClick = () => {
    setIsAddLeadDrawerOpen(true);
  };

  const handleLeadAdded = (newLead) => {
    setLeads([...leads, newLead]);
    setFilteredLeads([...filteredLeads, newLead]);
  };

  const handleEditClick = (event, lead) => {
    event.stopPropagation();
    setEditingLead(lead);
    setIsAddLeadDrawerOpen(true);
  };

  const handleLeadUpdated = (updatedLead) => {
    setLeads(leads.map(lead => lead._id === updatedLead._id ? updatedLead : lead));
    setFilteredLeads(filteredLeads.map(lead => lead._id === updatedLead._id ? updatedLead : lead));
    setEditingLead(null);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Leads
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search leads..."
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              sx={{ flexGrow: 1 }}
            />
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<AddIcon />}
              onClick={handleAddLeadClick}
            >
              Add Lead
            </Button>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                {['firstName', 'lastName', 'email', 'phone', 'status'].map((column) => (
                  <TableCell key={column}>
                    <TableSortLabel
                      active={orderBy === column}
                      direction={orderBy === column ? order : 'asc'}
                      onClick={() => handleRequestSort(column)}
                    >
                      {column.charAt(0).toUpperCase() + column.slice(1)}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLeads
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((lead) => (
                  <TableRow
                    key={lead._id}
                    hover
                    onClick={() => handleRowClick(lead._id)}
                  >
                    <TableCell>{lead.firstName ?? ''}</TableCell>
                    <TableCell>{lead.lastName ?? ''}</TableCell>
                    <TableCell>{lead.email ?? ''}</TableCell>
                    <TableCell>{lead.phone ?? ''}</TableCell>
                    <TableCell>{lead.status ?? ''}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={(e) => handleEditClick(e, lead)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={(e) => handleDeleteClick(e, lead)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredLeads.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this lead?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <AddLeadDrawer
        open={isAddLeadDrawerOpen}
        onClose={() => {
          setIsAddLeadDrawerOpen(false);
          setEditingLead(null);
        }}
        onLeadAdded={handleLeadAdded}
        onLeadUpdated={handleLeadUpdated}
        editingLead={editingLead}
      />
    </Container>
  );
};

export default LeadsPage;

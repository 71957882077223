import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CakeIcon from '@mui/icons-material/Cake';
import HomeIcon from '@mui/icons-material/Home';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import WcIcon from '@mui/icons-material/Wc';

const PatientDetails = ({ patient }) => {
  const details = [
    { icon: <EmailIcon fontSize="small" />, value: patient.email, label: 'Email' },
    { icon: <PhoneIcon fontSize="small" />, value: patient.phone, label: 'Phone' },
    { icon: <CakeIcon fontSize="small" />, value: new Date(patient.dateOfBirth).toLocaleDateString(), label: 'Date of Birth' },
    { icon: <HomeIcon fontSize="small" />, value: patient.address, label: 'Address' },
    { icon: <FingerprintIcon fontSize="small" />, value: patient.idNumber, label: 'ID Number' },
    { icon: <WcIcon fontSize="small" />, value: patient.gender, label: 'Gender' },
  ];

  return (
    <Box sx={{ p: 3, backgroundColor: 'background.paper', borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
        Patient Details
      </Typography>
      <Grid container spacing={2}>
        {details.map((detail, index) => (
          <Grid item xs={12} sm={6} key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ minWidth: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {detail.icon}
            </Box>
            <Typography variant="body1" sx={{ ml: 2, fontWeight: 500 }}>
              {detail.label}:
            </Typography>
            <Typography variant="body2" sx={{ ml: 1, color: 'text.secondary' }}>
              {detail.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PatientDetails;

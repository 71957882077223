import React, { useState, useEffect, useCallback } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Button,
  Box,
  Chip,
  Drawer,
  CircularProgress,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import api from '../api';
import { useAuth } from '../contexts/AuthContext';
import AddProviderPurchaseModal from './AddProviderPurchaseModal';
import PurchaseOrderDrawer from './PurchaseOrderDrawer';

function PurchaseOrdersTable() {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { clinic } = useAuth();

  const fetchPurchaseOrders = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await api.get(`/providerpurchases?clinicId=${clinic._id}`);
      if (Array.isArray(response.data.purchases)) {
        setPurchaseOrders(response.data.purchases);
      } else {
        console.error('Unexpected data structure:', response.data);
        setPurchaseOrders([]);
      }
    } catch (error) {
      console.error('Error fetching purchase orders:', error);
      setError('Failed to load purchase orders. Please try again.');
      setPurchaseOrders([]);
    } finally {
      setLoading(false);
    }
  }, [clinic._id]);

  useEffect(() => {
    fetchPurchaseOrders();
  }, [fetchPurchaseOrders]);

  const handleAddPurchase = () => {
    setIsAddModalOpen(true);
  };

  const handlePurchaseAdded = (newPurchase) => {
    setPurchaseOrders(prevOrders => [newPurchase, ...prevOrders]);
    setIsAddModalOpen(false);
  };

  const handleRowClick = (orderId) => {
    setSelectedOrderId(orderId);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = (updatedOrder) => {
    setIsDrawerOpen(false);
    setSelectedOrderId(null);

    if (updatedOrder) {
      setPurchaseOrders((prevOrders) =>
        prevOrders.map((order) => (order._id === updatedOrder._id ? updatedOrder : order))
      );
    }
  };

  const formatCurrency = (amount) => {
    return `$${amount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: '2-digit' });
  };

  const getStatusChip = (status) => {
    let color;
    switch (status) {
      case 'Ordered':
        color = 'primary';
        break;
      case 'Partially Received':
        color = 'warning';
        break;
      case 'Received':
        color = 'success';
        break;
      default:
        color = 'default';
    }
    return <Chip label={status} color={color} size="small" />;
  };

  const getPaymentStatusChip = (paymentStatus) => {
    let color;
    switch (paymentStatus) {
      case 'Paid':
        color = 'success';
        break;
      case 'Partial Paid':
        color = 'warning';
        break;
      case 'Pending':
        color = 'error';
        break;
      default:
        color = 'default';
    }
    return <Chip label={paymentStatus} color={color} size="small" />;
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddPurchase}
        >
          Add Purchase Order
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Order Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Provider</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Payment Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchaseOrders.map((order) => (
              <TableRow 
                key={order._id} 
                onClick={() => handleRowClick(order._id)}
                sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)', cursor: 'pointer' } }}
              >
                <TableCell>{formatDate(order.date)}</TableCell>
                {/* Ajuste para mostrar el nombre del proveedor */}
                <TableCell>{order.provider?.name || 'N/A'}</TableCell>
                <TableCell>{formatCurrency(order.total)}</TableCell>
                <TableCell>{getStatusChip(order.status)}</TableCell>
                <TableCell>{getPaymentStatusChip(order.paymentStatus)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddProviderPurchaseModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onPurchaseAdded={handlePurchaseAdded}
        clinicId={clinic._id}
      />
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
      >
        <PurchaseOrderDrawer
          orderId={selectedOrderId}
          onClose={handleDrawerClose}
          onOrderUpdated={fetchPurchaseOrders}
        />
      </Drawer>
    </>
  );
}

export default PurchaseOrdersTable;

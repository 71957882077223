// src/components/ProvidersTable.js
import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  IconButton,
  Button,
  Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import api from '../api';
import { useAuth } from '../contexts/AuthContext';
import AddProviderModal from './AddProviderModal';

function ProvidersTable() {
  const [providers, setProviders] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const { clinic } = useAuth();

  useEffect(() => {
    fetchProviders();
  }, [clinic]);

  const fetchProviders = async () => {
    try {
      const response = await api.get(`/providers?clinicId=${clinic._id}`);
      setProviders(response.data);
    } catch (error) {
      console.error('Error fetching providers:', error);
    }
  };

  const handleDeleteProvider = async (id) => {
    try {
      await api.delete(`/providers/${id}`);
      setProviders(providers.filter(provider => provider._id !== id));
    } catch (error) {
      console.error('Error deleting provider:', error);
      // Aquí podrías agregar una notificación de error para el usuario
    }
  };

  const handleAddProvider = () => {
    setIsAddModalOpen(true);
  };

  const handleProviderAdded = (newProvider) => {
    setProviders([...providers, newProvider]);
    setIsAddModalOpen(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddProvider}
        >
          Add Provider
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Document Type</TableCell>
              <TableCell>Document Number</TableCell>
              <TableCell>Purchase Order Preference</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {providers.map((provider) => (
              <TableRow key={provider._id}>
                <TableCell>{provider.name}</TableCell>
                <TableCell>{provider.email}</TableCell>
                <TableCell>{provider.phone}</TableCell>
                <TableCell>{provider.address}</TableCell>
                <TableCell>{provider.documentType}</TableCell>
                <TableCell>{provider.documentNumber}</TableCell>
                <TableCell>{provider.purchaseOrderPreference}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDeleteProvider(provider._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddProviderModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onProviderAdded={handleProviderAdded}
      />
    </>
  );
}

export default ProvidersTable;
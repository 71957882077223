import React from 'react';
import { Container, Typography } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

function HomePage() {
  const { user } = useAuth(); // Asumiendo que el contexto de autenticación proporciona la información del usuario

  return (
    <Container>
      <Typography variant="h2" gutterBottom>Home</Typography>
      <Typography variant="h5">
        Welcome to BeautyERP{user && user.clinicName ? `, ${user.clinicName}` : ''}!
      </Typography>
      <Typography variant="body1">
        Here you can manage your clinic's appointments, patients, and more.
      </Typography>
    </Container>
  );
}

export default HomePage;

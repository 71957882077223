import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box } from '@mui/material';
import api from '../api'; // Import the configured Axios instance

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(amount);
};

const AddTreatment = ({ open, onClose, onTreatmentAdded, initialTreatment }) => {
  const [formData, setFormData] = useState({
    name: '',
    duration: '',
    priceFrom: '',
    priceTo: ''
  });

  useEffect(() => {
    if (initialTreatment) {
      setFormData({
        name: initialTreatment.name,
        duration: initialTreatment.duration,
        priceFrom: initialTreatment.priceFrom || '',
        priceTo: initialTreatment.priceTo || ''
      });
    }
  }, [initialTreatment]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'priceFrom' || name === 'priceTo') {
      // Remove any non-numeric characters
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData({ ...formData, [name]: numericValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async () => {
    try {
      const dataToSend = {
        name: formData.name,
        duration: formData.duration,
        priceFrom: formData.priceFrom ? parseFloat(formData.priceFrom) : undefined,
        priceTo: formData.priceTo ? parseFloat(formData.priceTo) : undefined
      };

      let response;
      if (initialTreatment) {
        response = await api.put(`/treatments/${initialTreatment._id}`, dataToSend);
      } else {
        response = await api.post('/treatments', dataToSend);
      }
      onTreatmentAdded(response.data);
      onClose();
    } catch (error) {
      console.error('Error adding/editing treatment:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{initialTreatment ? 'Edit Treatment' : 'New Treatment'}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField
            name="name"
            label="Treatment Name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            required
            sx={{ mt: 2 }}
          />
          <TextField
            name="duration"
            label="Duration in minutes"
            type="number"
            value={formData.duration}
            onChange={handleChange}
            fullWidth
            required
            sx={{ mt: 2 }}
          />
          <TextField
            name="priceFrom"
            label="Price From"
            value={formData.priceFrom ? formatCurrency(formData.priceFrom) : ''}
            onChange={handleChange}
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            name="priceTo"
            label="Price To"
            value={formData.priceTo ? formatCurrency(formData.priceTo) : ''}
            onChange={handleChange}
            fullWidth
            sx={{ mt: 2 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          {initialTreatment ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTreatment;

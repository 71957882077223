import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';

const LeadDetailsCard = ({ lead }) => {
  if (!lead) {
    return null; // Or you could show a loading or error message
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Lead Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography variant="subtitle2">Name:</Typography>
              <Typography>{`${lead.firstName || ''} ${lead.lastName || ''}`}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography variant="subtitle2">Email:</Typography>
              <Typography>{lead.email || 'Not available'}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography variant="subtitle2">Phone:</Typography>
              <Typography>{lead.phone || 'Not available'}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography variant="subtitle2">Status:</Typography>
              <Typography>{lead.status || 'Not specified'}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography variant="subtitle2">Interest Score:</Typography>
              <Typography>{lead.interestScore !== undefined ? lead.interestScore : 'Not available'}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant="subtitle2">Notes:</Typography>
              <Typography>{lead.notes || 'No notes'}</Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LeadDetailsCard;

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CssBaseline from '@mui/material/CssBaseline';
import { Grid, Drawer, IconButton, AppBar, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Icono del menú hamburguesa

// Importing all necessary pages
import HomePage from './pages/HomePage';
import PatientsPage from './pages/PatientsPage';
import LeadsPage from './pages/LeadsPage';
import LeadDetailPage from './pages/LeadDetailPage';
import AppointmentsPage from './pages/AppointmentsPage';
import PatientDetailPage from './pages/PatientDetailPage';
import SettingsPage from './pages/SettingsPage';
import TreatmentsPage from './pages/TreatmentsPage';
import SchedulesPage from './pages/SchedulesPage';
import ProductsPage from './pages/ProductsPage';
import InventoryRecordsPage from './pages/InventoryRecordsPage';
import PatientPaymentPage from './pages/PatientPaymentPage';
import ClinicSignupPage from './pages/ClinicSignupPage';
import LoginPage from './pages/LoginPage';
import TransactionsPage from './pages/TransactionsPage';
import CashflowPage from './pages/CashflowPage';
import PLPage from './pages/PLPage';
import BalanceSheetPage from './pages/BalanceSheetPage';
import ConsentTemplatesPage from './pages/ConsentTemplatesPage';
import AISettings from './pages/AISettings';
import ImportDataPage from './pages/ImportDataPage';
import TemperatureHumidityControlPage from './pages/TemperatureHumidityControlPage';
import ProvidersAndInventoryPage from './pages/ProvidersAndInventoryPage';
import GoogleAuthCallback from './components/GoogleAuthCallback';
import ClinicSettings from './pages/ClinicSettings';

// Importing components
import Sidebar from './components/Sidebar';
import InformedConsentSignature from './components/InformedConsentSignature';
import AuthInterceptor from './components/AuthInterceptor';

// Importing context and utilities
import { useAuth } from './contexts/AuthContext';
import theme from './theme';

function PrivateRoute({ element }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/login" />;
}

function MainContent() {
  const { loading, isAuthenticated } = useAuth();

  if (loading) {
    return null;
  }

  return (
    <Grid
      container
      component="main"
      sx={{
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: isAuthenticated ? 'flex-start' : 'center', // Cambiado según autenticación
        alignItems: 'center',
        padding: '1rem',
        minHeight: '100vh',
      }}
    >
      <Routes>
        <Route path="/" element={<PrivateRoute element={<HomePage />} />} />
        <Route path="/signup" element={<ClinicSignupPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/patients" element={<PrivateRoute element={<PatientsPage />} />} />
        <Route path="/leads" element={<PrivateRoute element={<LeadsPage />} />} />
        <Route path="/leads/:leadId" element={<PrivateRoute element={<LeadDetailPage />} />} />
        <Route path="/appointments" element={<PrivateRoute element={<AppointmentsPage />} />} />
        <Route path="/patients/:id" element={<PrivateRoute element={<PatientDetailPage />} />} />
        <Route path="/settings" element={<PrivateRoute element={<SettingsPage />} />} />
        <Route path="/settings/treatments" element={<PrivateRoute element={<TreatmentsPage />} />} />
        <Route path="/settings/schedules" element={<PrivateRoute element={<SchedulesPage />} />} />
        <Route path="/settings/products" element={<PrivateRoute element={<ProductsPage />} />} />
        <Route path="/settings/inventory-records" element={<PrivateRoute element={<InventoryRecordsPage />} />} />
        <Route path="/settings/consents" element={<PrivateRoute element={<ConsentTemplatesPage />} />} />
        <Route path="/settings/ai-whatsapp" element={<PrivateRoute element={<AISettings />} />} />
        <Route path="/settings/import-data" element={<PrivateRoute element={<ImportDataPage />} />} />
        <Route path="/payments" element={<PrivateRoute element={<PatientPaymentPage />} />} />
        <Route path="/financials/transactions" element={<PrivateRoute element={<TransactionsPage />} />} />
        <Route path="/financials/cashflow" element={<PrivateRoute element={<CashflowPage />} />} />
        <Route path="/financials/pl" element={<PrivateRoute element={<PLPage />} />} />
        <Route path="/financials/balance-sheet" element={<PrivateRoute element={<BalanceSheetPage />} />} />
        <Route path="/settings/temperature-humidity" element={<PrivateRoute element={<TemperatureHumidityControlPage />} />} />
        <Route path="/settings/clinic" element={<PrivateRoute element={<ClinicSettings />} />} />
        <Route path="/signature/:id" element={<InformedConsentSignature />} />
        <Route path="/providers-and-inventory" element={<PrivateRoute element={<ProvidersAndInventoryPage />} />}  />
        <Route path="/google-auth-callback" element={<GoogleAuthCallback />} />
      </Routes>
    </Grid>
  );
}

function App() {
  const { isAuthenticated } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Estado para controlar el Drawer

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router>
          <AuthInterceptor>
            <Grid container sx={{ height: '100vh', width: '100vw' }}>
              {isAuthenticated && (
                <>
                  {/* AppBar visible en pantallas pequeñas para el menú hamburguesa */}
                  <AppBar position="fixed" sx={{ display: { md: 'none' } }}>
                    <Toolbar>
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                      >
                        <MenuIcon />
                      </IconButton>
                    </Toolbar>
                  </AppBar>

                  {/* Drawer para pantallas pequeñas */}
                  <Drawer
                    variant="temporary"
                    open={isDrawerOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                      keepMounted: true, // Mejora el rendimiento en móviles
                    }}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '250px' },
                    }}
                  >
                    <Sidebar />
                  </Drawer>

                  {/* Sidebar para pantallas medianas y grandes */}
                  <Grid
                    item
                    xs={0}
                    md={2} // Sidebar ocupa 2 columnas en pantallas medianas en adelante
                    sx={{ display: { xs: 'none', md: 'block' }, height: '100vh', overflowY: 'auto' }}
                  >
                    <Sidebar />
                  </Grid>
                </>
              )}

              {/* MainContent, ocupa todo el ancho en pantallas pequeñas y 10 columnas en pantallas grandes */}
              <Grid
                item
                xs={12}
                md={isAuthenticated ? 10 : 12} // Ajuste para que ocupe todo el ancho si no está autenticado
                sx={{ height: '100vh', overflowY: 'auto', marginTop: { xs: '56px', md: '0px' } }} // Ajuste para AppBar en móviles
              >
                <MainContent />
              </Grid>
            </Grid>
          </AuthInterceptor>
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;

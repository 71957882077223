import React from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Mention from '@tiptap/extension-mention';
import suggestion from './suggestion'; // Importamos el archivo de sugerencias

// Importa los estilos personalizados
import './styles.scss';

const TiptapEditor = ({ content, setContent }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Mention.configure({
        HTMLAttributes: {
          class: 'mention', // Clase personalizada para menciones
        },
        suggestion,
      }),
    ],
    content: content || '',
    onUpdate: ({ editor }) => {
      setContent(editor.getHTML());
    },
    editorProps: {
      attributes: {
        class: 'tiptap-editor', // Clase para el contenedor del editor
      },
    },
  });

  if (!editor) {
    return null;
  }

  return (
    // Uso directo del componente EditorContent sin contenedores adicionales
    <EditorContent editor={editor} className="tiptap-editor" />
  );
};

export default TiptapEditor;

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Grid, Typography } from '@mui/material';
import api from '../api';

const units = ["vial", "bottle", "box", "unit", "ml", "cc", "gram"];

const EditProductModal = ({ open, onClose, onProductUpdated, product }) => {
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    purchaseUnit: { unitMeasure: '', quantity: '' },
    usageUnit: { unitMeasure: '', quantity: '' },
    minimumUsageStock: '',
    currentStock: 0,
    sanitaryRegistry: ''
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (product) {
      setFormData({
        name: product.name || '',
        type: product.type || '',
        purchaseUnit: product.purchaseUnit || { unitMeasure: '', quantity: '' },
        usageUnit: product.usageUnit || { unitMeasure: '', quantity: '' },
        minimumUsageStock: product.minimumUsageStock || '',
        currentStock: product.currentStock || 0,
        sanitaryRegistry: product.sanitaryRegistry || ''
      });
    }
  }, [product]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors({ ...errors, [name]: '' });
  };

  const handleUnitChange = (unitType, field, value) => {
    setFormData(prevState => ({
      ...prevState,
      [unitType]: {
        ...prevState[unitType],
        [field]: value
      }
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [unitType]: {
        ...prevErrors[unitType],
        [field]: ''
      }
    }));
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : "This field is required.";
    tempErrors.type = formData.type ? "" : "This field is required.";
    tempErrors.purchaseUnit = {};
    tempErrors.purchaseUnit.unitMeasure = formData.purchaseUnit.unitMeasure ? "" : "This field is required.";
    tempErrors.purchaseUnit.quantity = formData.purchaseUnit.quantity ? "" : "This field is required.";
    tempErrors.usageUnit = {};
    tempErrors.usageUnit.unitMeasure = formData.usageUnit.unitMeasure ? "" : "This field is required.";
    tempErrors.usageUnit.quantity = formData.usageUnit.quantity ? "" : "This field is required.";
    tempErrors.minimumUsageStock = formData.minimumUsageStock ? "" : "This field is required.";
    tempErrors.sanitaryRegistry = formData.sanitaryRegistry ? "" : "This field is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "" || (typeof x === 'object' && Object.values(x).every(y => y === "")));
  };

  const handleSubmit = async () => {
    if (validate()) {
      try {
        const response = await api.put(`/products/${product._id}`, formData);
        onProductUpdated(response.data);
        onClose();
      } catch (error) {
        console.error('Error updating product:', error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Product</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              label="Name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              name="type"
              label="Type"
              value={formData.type}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={!!errors.type}
              helperText={errors.type}
            >
              <MenuItem value="Consumable">Consumable</MenuItem>
              <MenuItem value="Machine">Machine</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Purchase Information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              name="purchaseUnit.unitMeasure"
              label="Purchase Unit"
              value={formData.purchaseUnit.unitMeasure}
              onChange={(e) => handleUnitChange('purchaseUnit', 'unitMeasure', e.target.value)}
              fullWidth
              margin="normal"
              error={!!errors.purchaseUnit?.unitMeasure}
              helperText={errors.purchaseUnit?.unitMeasure}
            >
              {units.map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="purchaseUnit.quantity"
              label="Purchase Quantity"
              type="number"
              value={formData.purchaseUnit.quantity}
              onChange={(e) => handleUnitChange('purchaseUnit', 'quantity', e.target.value)}
              fullWidth
              margin="normal"
              error={!!errors.purchaseUnit?.quantity}
              helperText={errors.purchaseUnit?.quantity || "How many units are in one purchase?"}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Usage Information</Typography>
            <Typography variant="body2" color="textSecondary">
              Specify how the product is used. For example, if you buy a vial of Botox (Purchase Unit: vial, Purchase Quantity: 1)
              but use it in individual units, you would enter:
              <br />
              - Usage Unit: unit
              - Usage Quantity: 100 (if one vial contains 100 units)
              <br />
              This means that one vial (purchase unit) contains 100 individual units (usage units).
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              name="usageUnit.unitMeasure"
              label="Usage Unit"
              value={formData.usageUnit.unitMeasure}
              onChange={(e) => handleUnitChange('usageUnit', 'unitMeasure', e.target.value)}
              fullWidth
              margin="normal"
              error={!!errors.usageUnit?.unitMeasure}
              helperText={errors.usageUnit?.unitMeasure}
            >
              {units.map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="usageUnit.quantity"
              label="Usage Quantity (per Purchase Unit)"
              type="number"
              value={formData.usageUnit.quantity}
              onChange={(e) => handleUnitChange('usageUnit', 'quantity', e.target.value)}
              fullWidth
              margin="normal"
              error={!!errors.usageUnit?.quantity}
              helperText={errors.usageUnit?.quantity || "How many usage units are in one purchase unit?"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="minimumUsageStock"
              label="Minimum Usage Stock"
              type="number"
              value={formData.minimumUsageStock}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={!!errors.minimumUsageStock}
              helperText={errors.minimumUsageStock || "Minimum stock in usage units"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="currentStock"
              label="Current Stock"
              type="number"
              value={formData.currentStock}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={!!errors.currentStock}
              helperText={errors.currentStock || "Current stock in usage units"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="sanitaryRegistry"
              label="Sanitary Registry"
              value={formData.sanitaryRegistry}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={!!errors.sanitaryRegistry}
              helperText={errors.sanitaryRegistry}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProductModal;
import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Autocomplete, InputAdornment, Box, CircularProgress, MenuItem
} from '@mui/material'; // Asegúrate de importar MenuItem
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; // Asegúrate de importar dayjs
import api from '../api';

const PayablePaymentDialog = ({ open, onClose, onPaymentAdded }) => {
  const [payables, setPayables] = useState([]);
  const [selectedPayable, setSelectedPayable] = useState(null);
  const [amount, setAmount] = useState('');
  const [account, setAccount] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [date, setDate] = useState(dayjs());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [payablesResponse, accountsResponse] = await Promise.all([
        api.get('/financialTransactions/payables'),
        api.get('/accounts')
      ]);
      setPayables(payablesResponse.data);
      const filteredAccounts = accountsResponse.data.filter(acc => acc.code === '1101' || acc.code === '1102');
      setAccounts(filteredAccounts);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const handleAddPayment = async () => {
    try {
      const newPayment = {
        payableId: selectedPayable._id,
        amount: parseFloat(amount.replace(/,/g, '').replace('$', '')),
        account,
        date: date.toISOString(), // Ensure the date is sent in ISO format
      };
      const response = await api.post('/financialTransactions/payments', newPayment);
      onPaymentAdded(response.data);
      handleClose();
    } catch (error) {
      console.error('Error adding payment:', error);
    }
  };

  const handleClose = () => {
    onClose();
    // Reset form fields
    setSelectedPayable(null);
    setAmount('');
    setAccount('');
    setDate(dayjs());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Pay Accounts Payable</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Autocomplete
                options={payables}
                getOptionLabel={(option) => `${option.description} - $${option.remainingAmount.toLocaleString()}`}
                renderInput={(params) => <TextField {...params} label="Select Payable" variant="outlined" margin="dense" />}
                value={selectedPayable}
                onChange={(event, newValue) => setSelectedPayable(newValue)}
                fullWidth
                margin="dense"
              />
              <TextField
                label="Amount"
                fullWidth
                variant="outlined"
                margin="dense"
                value={amount}
                onChange={(e) => setAmount(new Intl.NumberFormat().format(e.target.value.replace(/,/g, '').replace('$', '')))}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              <TextField
                select
                label="Account"
                fullWidth
                margin="dense"
                variant="outlined"
                value={account}
                onChange={(e) => setAccount(e.target.value)}
              >
                {accounts.map((acc) => (
                  <MenuItem key={acc._id} value={acc._id}>
                    {acc.name}
                  </MenuItem>
                ))}
              </TextField>
              <DatePicker
                label="Date"
                value={date}
                onChange={(newValue) => setDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="dense" variant="outlined" />}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddPayment} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default PayablePaymentDialog;

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  IconButton,
  Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../api';

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString();
};

const AttachmentList = ({ patientId, refreshTrigger }) => {
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAttachments = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(`/attachments/${patientId}`);
        setAttachments(response.data);
      } catch (error) {
        console.error('Error fetching attachments:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAttachments();
  }, [patientId, refreshTrigger]);

  const handleRowClick = async (attachment) => {
    try {
      const response = await api.get(`/attachments/${attachment._id}/presigned-url`);
      const { url } = response.data;
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error opening attachment:', error);
    }
  };

  const handleDelete = async (event, attachmentId) => {
    event.stopPropagation(); // Previene que el clic se propague a la fila
    try {
      await api.delete(`/attachments/${attachmentId}`);
      setAttachments(attachments.filter(attachment => attachment._id !== attachmentId));
    } catch (error) {
      console.error('Error deleting attachment:', error);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper elevation={3}>
      <TableContainer>
        <Typography variant="h6" gutterBottom component="div" sx={{ p: 2 }}>
          Attachments
        </Typography>
        {attachments.length === 0 ? (
          <Typography sx={{ p: 2 }}>No attachments found.</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>File Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Upload Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attachments.map((attachment) => (
                <TableRow
                  key={attachment._id}
                  onClick={() => handleRowClick(attachment)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    },
                  }}
                >
                  <TableCell>{attachment.fileName}</TableCell>
                  <TableCell>{formatDate(attachment.uploadDate)}</TableCell>
                  <TableCell>
                    <IconButton 
                      onClick={(event) => handleDelete(event, attachment._id)} 
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Paper>
  );
};

export default AttachmentList;
import React, { useState, useEffect } from 'react';
import api from '../api';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, MenuItem, Grid, Box, Typography, Autocomplete } from '@mui/material';
import ClinicalHistoryForm from './ClinicalHistoryForm';
import { useAuth } from '../contexts/AuthContext';

const PatientPaymentForm = ({ open, onClose, onPaymentAdded }) => {
  const [formData, setFormData] = useState({
    patient: '',
    amount: '',
    account: '',
    clinicalHistory: ''
  });

  const [patients, setPatients] = useState([]);
  const [clinicalHistories, setClinicalHistories] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [openClinicalHistoryForm, setOpenClinicalHistoryForm] = useState(false);
  const { clinic } = useAuth();

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await api.get('/patients', {
          params: { clinicId: clinic._id }
        });
        setPatients(response.data);
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    };

    const fetchAccounts = async () => {
      try {
        const response = await api.get('/accounts', {
          params: { 
            type: 'asset',
            codes: '1101,1102' // Ensure codes are passed as a comma-separated string
          }
        });
        setAccounts(response.data);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    if (clinic) {
      fetchPatients();
      fetchAccounts();
    }
  }, [clinic]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const handlePatientChange = async (event, newValue) => {
    if (newValue) {
      setFormData({ ...formData, patient: newValue._id });

      try {
        const response = await api.get(`/clinicalHistories/patient/${newValue._id}/pending-payments`, {
          params: { clinicId: clinic._id }
        });

        const formattedHistories = response.data.map(history => {
          const totalPaid = history.totalPaid || 0;
          const pendingAmount = history.invoiceAmount - totalPaid;

          const treatmentNames = history.treatmentsPerformed.map(treatment => treatment.treatment.name).join(', ');
          return {
            ...history,
            formattedLabel: `${treatmentNames} - ${formatCurrency(pendingAmount)}`,
            pendingAmount
          };
        });

        setClinicalHistories(formattedHistories);
      } catch (error) {
        console.error('Error fetching clinical histories:', error);
      }
    } else {
      setFormData({ ...formData, patient: '', clinicalHistory: '' });
      setClinicalHistories([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'amount') {
      // Remove non-digit characters and format the amount
      const numericValue = value.replace(/[^\d]/g, '');
      const formattedValue = formatCurrency(parseInt(numericValue) || 0);
      setFormData({ ...formData, [name]: formattedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    if (name === 'clinicalHistory' && value === 'addNew') {
      setOpenClinicalHistoryForm(true);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await api.post('/patientPayments', {
        ...formData,
        clinic: clinic._id,
        amount: parseFloat(formData.amount.replace(/[^\d]/g, ''))
      });
      onPaymentAdded(response.data);
      onClose();
    } catch (error) {
      console.error('Error adding payment:', error);
    }
  };

  const handleHistoryAdded = async (newHistory) => {
    setClinicalHistories([...clinicalHistories, newHistory]);
    setFormData({ ...formData, clinicalHistory: newHistory._id });
    setOpenClinicalHistoryForm(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Add Patient Payment</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  options={patients}
                  getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                  onChange={handlePatientChange}
                  renderInput={(params) => <TextField {...params} label="Patient" fullWidth />}
                  // Add key to ensure unique keys
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.firstName} {option.lastName}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Clinical History"
                  name="clinicalHistory"
                  value={formData.clinicalHistory}
                  onChange={handleChange}
                  fullWidth
                >
                  {clinicalHistories.map((history) => (
                    <MenuItem key={history._id} value={history._id}>
                      {history.formattedLabel}
                    </MenuItem>
                  ))}
                  <MenuItem value="addNew">
                    <em>Add New Clinical History</em>
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Amount"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{ inputMode: 'numeric' }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={accounts}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, account: newValue ? newValue._id : '' });
                  }}
                  renderInput={(params) => <TextField {...params} label="Account" fullWidth />}
                  // Add key to ensure unique keys
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.name}
                    </li>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">Submit</Button>
        </DialogActions>
      </Dialog>

      <ClinicalHistoryForm
        open={openClinicalHistoryForm}
        onClose={() => setOpenClinicalHistoryForm(false)}
        patientId={formData.patient}
        onHistoryAdded={handleHistoryAdded}
        showPaymentFields={false}
      />
    </>
  );
};

export default PatientPaymentForm;

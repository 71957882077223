import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, Snackbar, Alert, MenuItem, InputAdornment
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import api from '../api';

const ShareCapitalDialog = ({ open, onClose, onShareCapitalAdded }) => {
  const [shareholder, setShareholder] = useState('');
  const [amount, setAmount] = useState('');
  const [issueDate, setIssueDate] = useState(null);
  const [description, setDescription] = useState('');
  const [account, setAccount] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await api.get('/accounts');
        const filteredAccounts = response.data.filter(acc => acc.code === '1101' || acc.code === '1102');
        setAccounts(filteredAccounts);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    fetchAccounts();
  }, []);

  const handleAddShareCapital = async () => {
    try {
      const newShareCapital = {
        shareholder,
        amount: parseFloat(amount.replace(/,/g, '').replace('$', '')),
        issueDate,
        description,
        account
      };
      const response = await api.post('/shareCapital/issue', newShareCapital);
      onShareCapitalAdded(response.data);
      setSnackbarMessage('Share capital added successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      onClose();
    } catch (error) {
      setSnackbarMessage('Error adding share capital');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Share Capital</DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              margin="dense"
              label="Shareholder"
              fullWidth
              variant="outlined"
              value={shareholder}
              onChange={(e) => setShareholder(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Amount"
              fullWidth
              variant="outlined"
              value={amount}
              onChange={(e) => setAmount(new Intl.NumberFormat().format(e.target.value.replace(/,/g, '').replace('$', '')))}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <DatePicker
              label="Issue Date"
              value={issueDate}
              onChange={(newValue) => setIssueDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" variant="outlined" />}
            />
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              select
              label="Account"
              fullWidth
              margin="dense"
              variant="outlined"
              value={account}
              onChange={(e) => setAccount(e.target.value)}
            >
              {accounts.map((acc) => (
                <MenuItem key={acc._id} value={acc._id}>
                  {acc.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddShareCapital} color="primary">
            Save
          </Button>
        </DialogActions>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Dialog>
    </LocalizationProvider>
  );
};

export default ShareCapitalDialog;

import React, { useState } from 'react';
import { Button, TextField, CircularProgress } from '@mui/material';
import api from '../api';

const FileUploadForm = ({ patientId, onUploadSuccess, onUploadError }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      onUploadError('Please select a file');
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      await api.post(`/attachments/${patientId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      onUploadSuccess();
    } catch (error) {
      onUploadError(error.response?.data?.message || 'Error uploading file');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        type="file"
        onChange={handleFileChange}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button 
        type="submit" 
        variant="contained" 
        color="primary" 
        disabled={loading || !file}
      >
        {loading ? <CircularProgress size={24} /> : 'Upload'}
      </Button>
    </form>
  );
};

export default FileUploadForm;

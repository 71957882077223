import React from 'react';
import { Grid, TextField, MenuItem, Box, Typography, Container } from '@mui/material';

const PhysicalExamSection = ({ formData = {}, handleChange, handleFocus }) => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom align="center">
          Physical Exam
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Weight"
              name="weight"
              value={formData.weight}
              onChange={handleChange}
              onFocus={handleFocus}
              sx={{ minWidth: 150 }}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Height (cm) *"
              name="height"
              type="number"
              value={formData.height || 0}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="BMI (calculated)"
              name="bmi"
              type="number"
              value={formData.bmi || 0}
              onChange={handleChange}
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              label="Skin Type"
              name="skinType"
              value={formData.skinType || ''}
              onChange={handleChange}
              fullWidth
              margin="normal"
            >
              <MenuItem value="Sensible">Sensible</MenuItem>
              <MenuItem value="Seca">Seca</MenuItem>
              <MenuItem value="Normal">Normal</MenuItem>
              <MenuItem value="Grasa">Grasa</MenuItem>
              <MenuItem value="Mixta">Mixta</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              label="Fitzpatrick Scale"
              name="fitzpatrickScale"
              value={formData.fitzpatrickScale || 'I'}
              onChange={handleChange}
              fullWidth
              margin="normal"
            >
              <MenuItem value="I">I</MenuItem>
              <MenuItem value="II">II</MenuItem>
              <MenuItem value="III">III</MenuItem>
              <MenuItem value="IV">IV</MenuItem>
              <MenuItem value="V">V</MenuItem>
              <MenuItem value="VI">VI</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Observations"
              name="observations"
              value={formData.observations || ''}
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PhysicalExamSection;

import React, { useEffect, useState, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import SignaturePadComponent from './SignaturePad';

const InformedConsentSignature = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [consentContent, setConsentContent] = useState('');
  const signaturePadRef = useRef(null);

  useEffect(() => {
    const fetchInformedConsent = async () => {
      try {
        const baseUrl = process.env.REACT_APP_BACKEND_URL;
        const url = `${baseUrl}/api/patient/informed-consents/${id}?token=${token}`;
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.content) {
          setConsentContent(data.content);
        } else {
          console.error('No se encontró el contenido del consentimiento informado');
        }
      } catch (error) {
        console.error('Error al cargar el consentimiento informado:', error);
      }
    };

    fetchInformedConsent();
  }, [id, token]);

  const handleSaveSignature = async (signature) => {
    try {
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(`${baseUrl}/api/signature/sign`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ informedConsentId: id, signatureData: signature })
      });

      if (response.ok) {
        alert('Firma enviada con éxito');
      } else {
        alert('Error al enviar la firma');
      }
    } catch (error) {
      console.error('Error al enviar la firma:', error);
    }
  };

  return (
    <div style={{ maxWidth: '900px', margin: '0 auto', padding: '20px' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Firma de Consentimiento Informado</h1>
      <div
        style={{
          backgroundColor: '#f9f9f9',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          marginBottom: '20px',
          overflowX: 'auto' // Para manejar cualquier desbordamiento horizontal
        }}
        dangerouslySetInnerHTML={{ __html: consentContent }}
      ></div>
      <SignaturePadComponent onSave={handleSaveSignature} ref={signaturePadRef} />
    </div>
  );
};

export default InformedConsentSignature;

import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Button,
  TextField,
  IconButton,
  Typography,
  Box,
  Autocomplete,
  Divider,
  Paper
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon, Delete as DeleteIcon } from '@mui/icons-material';
import api from '../api';

const getTodayDate = () => {
  const today = new Date();
  return today.toISOString().split('T')[0];
};

const AddProviderPurchaseModal = ({ open, onClose, onPurchaseAdded, clinicId }) => {
  const [providers, setProviders] = useState([]);
  const [products, setProducts] = useState([]);
  const [formData, setFormData] = useState({
    provider: null,
    date: getTodayDate(),
    products: [{ product: null, quantity: '', unitPrice: '' }],
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchProviders = async () => {
      const response = await api.get('/providers');
      setProviders(response.data);
    };

    const fetchProducts = async () => {
      const response = await api.get('/products');
      setProducts(response.data);
    };

    fetchProviders();
    fetchProducts();
  }, []);

  const handleProviderChange = (event, newValue) => {
    setFormData({ ...formData, provider: newValue });
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...formData.products];
    updatedProducts[index][field] = value;
    setFormData({ ...formData, products: updatedProducts });
  };

  const handleAddProduct = () => {
    setFormData({
      ...formData,
      products: [...formData.products, { product: null, quantity: '', unitPrice: '' }],
    });
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = [...formData.products];
    updatedProducts.splice(index, 1);
    setFormData({ ...formData, products: updatedProducts });
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const total = formData.products.reduce((acc, product) => 
      acc + (Number(product.quantity) * Number(product.unitPrice)), 0
    );
    
    const purchaseData = {
      provider: formData.provider?._id,
      providerName: formData.provider?.name,
      date: formData.date,
      products: formData.products.map(product => ({
        product: product.product?._id,
        productName: product.product?.name,
        quantity: Number(product.quantity),
        unitPrice: Number(product.unitPrice)
      })),
      total,
      clinic: clinicId,
      status: 'Ordered',
      paymentStatus: 'Pending'
    };

    try {
      const response = await api.post('/providerpurchases', purchaseData);
      onPurchaseAdded(response.data);
      onClose();
      setFormData({
        provider: null,
        date: getTodayDate(),
        products: [{ product: null, quantity: '', unitPrice: '' }],
      });
    } catch (error) {
      console.error('Error adding purchase:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose} PaperProps={{ sx: { width: '400px' } }}>
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Add Provider Purchase
        </Typography>
        
        <Paper elevation={0} sx={{ p: 2, mb: 2, border: '1px solid #e0e0e0', borderRadius: 2 }}>
          <Autocomplete
            value={formData.provider}
            onChange={handleProviderChange}
            options={providers}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Provider" variant="outlined" fullWidth margin="normal" size="small" />
            )}
          />
          <TextField
            label="Date"
            name="date"
            type="date"
            value={formData.date}
            onChange={(e) => setFormData({ ...formData, date: e.target.value })}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Paper>
        
        <Paper elevation={0} sx={{ p: 2, mb: 2, border: '1px solid #e0e0e0', borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom>
            Products
          </Typography>
          {formData.products.map((product, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Autocomplete
                value={product.product}
                onChange={(e, newValue) => handleProductChange(index, 'product', newValue)}
                options={products}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Product" variant="outlined" fullWidth margin="normal" size="small" />
                )}
              />
              <TextField
                label="Quantity"
                name="quantity"
                type="number"
                value={product.quantity}
                onChange={(e) => handleProductChange(index, 'quantity', e.target.value)}
                fullWidth
                margin="normal"
                size="small"
              />
              <TextField
                label="Unit Price"
                name="unitPrice"
                type="number"
                value={product.unitPrice}
                onChange={(e) => handleProductChange(index, 'unitPrice', e.target.value)}
                fullWidth
                margin="normal"
                size="small"
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <IconButton onClick={() => handleRemoveProduct(index)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
              {index < formData.products.length - 1 && <Divider sx={{ my: 2 }} />}
            </Box>
          ))}
          <Button onClick={handleAddProduct} color="primary" startIcon={<AddIcon />}>
            Add Product
          </Button>
        </Paper>
        
        <Box sx={{ mt: 3 }}>
          <Button onClick={onClose} color="secondary" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={isSubmitting}>
            Save
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AddProviderPurchaseModal;
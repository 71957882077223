import React, { useState, useEffect } from 'react';
import api from '../api';
import {
  Dialog,
  Button,
  Box,
  Typography,
  Autocomplete,
  TextField,
  Grid,
} from '@mui/material';
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const AppointmentModal = ({ open, onClose, onAppointmentAdded }) => {
  const [formData, setFormData] = useState({
    patient: null,
    startDate: dayjs(),
    startTime: dayjs(),
    treatment: null,
  });
  const [patients, setPatients] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [errors, setErrors] = useState({});
  const [treatmentDuration, setTreatmentDuration] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPatientsAndTreatments = async () => {
      try {
        const [patientsResponse, treatmentsResponse] = await Promise.all([
          api.get('/patients'),
          api.get('/treatments'),
        ]);
        setPatients(patientsResponse.data);
        setTreatments(treatmentsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
      }
    };

    fetchPatientsAndTreatments();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleDateChange = (newDate) => {
    setFormData({ ...formData, startDate: newDate });
  };

  const handleTimeChange = (newTime) => {
    setFormData({ ...formData, startTime: newTime });
  };

  const handlePatientChange = (event, newValue) => {
    setFormData({ ...formData, patient: newValue });
  };

  const handleTreatmentChange = (event, newValue) => {
    setFormData({ ...formData, treatment: newValue });
    setTreatmentDuration(newValue ? newValue.duration : 0);
  };

  const handleSubmit = async () => {
    const newErrors = {};

    if (!formData.patient) newErrors.patient = 'Patient is required';
    if (!formData.startDate) newErrors.startDate = 'Date is required';
    if (!formData.startTime) newErrors.startTime = 'Time is required';
    if (!formData.treatment) newErrors.treatment = 'Treatment is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const startDateTime = dayjs(formData.startDate)
      .hour(formData.startTime.hour())
      .minute(formData.startTime.minute());
    const endDateTime = startDateTime.add(treatmentDuration, 'minute');

    const appointmentData = {
      patientId: formData.patient._id,
      startDate: startDateTime.toISOString(),
      endDate: endDateTime.toISOString(),
      appointmentLength: treatmentDuration,
      treatment: formData.treatment._id,
      description: formData.treatment.name,
    };

    try {
      const response = await api.post('/appointments', appointmentData);

      if (response.status === 201 || response.status === 200) {
        onAppointmentAdded(response.data);
        setError(null);
        onClose();
      } else {
        setError('Unexpected response from the server.');
      }
    } catch (error) {
      console.error('Error creating appointment:', error);
      setError('Error creating appointment');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ p: 4 }}>
          <Typography component="h1" variant="h5" sx={{ textAlign: 'center', mb: 3 }}>
            New Appointment
          </Typography>

          {error && (
            <Typography variant="body2" color="error" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}

          <Grid container spacing={2}>
            {/* Selección del paciente */}
            <Grid item xs={12}>
              <Autocomplete
                options={patients}
                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                onChange={handlePatientChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Patient"
                    variant="outlined"
                    error={!!errors.patient}
                    helperText={errors.patient}
                    fullWidth
                  />
                )}
              />
            </Grid>

            {/* Fecha y hora */}
            <Grid item xs={12} md={6}>
              <DatePicker
                label="Select Date"
                value={formData.startDate}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    error={!!errors.startDate}
                    helperText={errors.startDate}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TimePicker
                label="Select Time"
                value={formData.startTime}
                onChange={handleTimeChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    error={!!errors.startTime}
                    helperText={errors.startTime}
                    fullWidth
                  />
                )}
              />
            </Grid>

            {/* Selección del tratamiento */}
            <Grid item xs={12}>
              <Autocomplete
                options={treatments}
                getOptionLabel={(option) => option.name}
                onChange={handleTreatmentChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Treatment"
                    variant="outlined"
                    error={!!errors.treatment}
                    helperText={errors.treatment}
                    fullWidth
                  />
                )}
              />
            </Grid>

            {/* Botones de acción */}
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={handleSubmit} color="primary" variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
    </Dialog>
  );
};

export default AppointmentModal;

import React, { useState, useEffect } from 'react';
import api from '../api';
import { Dialog, DialogTitle, DialogActions, Button, MenuItem, Box, Typography, TextField } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import TiptapEditor from './TiptapEditor/TiptapEditor'; // Importamos el nuevo componente TiptapEditor

const ConsentTemplateDialog = ({ open, onClose, onTemplateAdded, templateToEdit }) => {
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    treatmentId: ''
  });

  const [treatments, setTreatments] = useState([]);
  const { clinic } = useAuth();

  useEffect(() => {
    const fetchTreatments = async () => {
      try {
        const response = await api.get('/treatments', {
          params: {
            clinicId: clinic._id
          }
        });
        setTreatments(response.data);
      } catch (error) {
        console.error('Error fetching treatments:', error);
      }
    };

    if (clinic) {
      fetchTreatments();
    }
  }, [clinic]);

  useEffect(() => {
    if (templateToEdit) {
      setFormData({
        title: templateToEdit.title || '',
        content: templateToEdit.content || '',
        treatmentId: templateToEdit.treatmentId || ''
      });
    }
  }, [templateToEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      if (templateToEdit) {
        await api.put(`/consent-templates/${templateToEdit._id}`, {
          ...formData,
          clinic: clinic._id
        });
        onTemplateAdded({ ...formData, _id: templateToEdit._id });
      } else {
        const response = await api.post('/consent-templates', {
          ...formData,
          clinic: clinic._id
        });
        onTemplateAdded(response.data);
      }
      onClose();
    } catch (error) {
      console.error('Error saving template:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="body" fullScreen>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          p: 3,
          backgroundColor: 'background.default',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '400px',
            backgroundColor: 'transparent',
          }}
        >
          <Typography component="h1" variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
            {templateToEdit ? 'Edit Consent Template' : 'Add Consent Template'}
          </Typography>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              select
              label="Treatment"
              name="treatmentId"
              value={formData.treatmentId}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              required
            >
              {treatments.map((treatment) => (
                <MenuItem key={treatment._id} value={treatment._id}>
                  {treatment.name}
                </MenuItem>
              ))}
            </TextField>
            <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
            You can use placeholders like <strong>{'{{patient_name}}'}</strong>, <strong>{'{{treatment_name}}'}</strong>, etc. To insert dynamic fields, type <strong>@</strong> and select from the options.
            </Typography>

            {/* Integración del editor Tiptap sin ningún contenedor extra */}
            <TiptapEditor content={formData.content} setContent={(newContent) => setFormData({ ...formData, content: newContent })} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={handleSubmit} color="primary" variant="contained">
                {templateToEdit ? 'Update' : 'Create'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConsentTemplateDialog;

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Snackbar, Alert, MenuItem, InputAdornment } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import api from '../api'; // Asegúrate de que el path sea correcto

const AddPPE = ({ open, onClose, onPPEAdded }) => {
  // Estados para manejar los datos del formulario y la interfaz de usuario
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [acquisitionDate, setAcquisitionDate] = useState(null);
  const [acquisitionCost, setAcquisitionCost] = useState('');
  const [usefulLife, setUsefulLife] = useState('');
  const [salvageValue, setSalvageValue] = useState('');
  const [account, setAccount] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Efecto para obtener las cuentas cuando el componente se monta
  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await api.get('/accounts'); // Ajusta el endpoint según sea necesario
        // Filtrar cuentas con los códigos "1101" y "1102"
        const filteredAccounts = response.data.filter(account => 
          account.code === '1101' || account.code === '1102'
        );
        setAccounts(filteredAccounts);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    fetchAccounts();
  }, []);

  // Manejar el envío del formulario
  const handleSubmit = async () => {
    const newPPE = {
      name,
      description,
      acquisitionDate,
      acquisitionCost: parseFloat(acquisitionCost.replace(/,/g, '').replace('$', '')), // Eliminar separadores de miles y el símbolo de dólar
      usefulLife: parseInt(usefulLife, 10),
      salvageValue: parseFloat(salvageValue.replace(/,/g, '').replace('$', '')), // Eliminar separadores de miles y el símbolo de dólar
      account,
    };

    try {
      const response = await api.post('/ppe', newPPE);
      onPPEAdded(response.data);
      setSnackbarMessage('PPE created successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      onClose();
    } catch (error) {
      console.error('Error creating PPE:', error);
      setSnackbarMessage('Error creating PPE.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Manejar el cierre de la notificación
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Add Property, Plant, and Equipment</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            margin="normal"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Acquisition Date"
              value={acquisitionDate}
              onChange={(newValue) => setAcquisitionDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
            />
          </LocalizationProvider>
          <TextField
            label="Acquisition Cost"
            value={acquisitionCost}
            onChange={(e) => setAcquisitionCost(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(e.target.value.replace(/,/g, '').replace('$', '')))}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
          <TextField
            label="Useful Life (years)"
            value={usefulLife}
            onChange={(e) => setUsefulLife(e.target.value)}
            fullWidth
            margin="normal"
            type="number"
          />
          <TextField
            label="Salvage Value"
            value={salvageValue}
            onChange={(e) => setSalvageValue(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(e.target.value.replace(/,/g, '').replace('$', '')))}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
          <TextField
            select
            label="Account"
            value={account}
            onChange={(e) => setAccount(e.target.value)}
            fullWidth
            margin="normal"
          >
            {accounts.map((account) => (
              <MenuItem key={account._id} value={account._id}>
                {account.name} - {account.code}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddPPE;

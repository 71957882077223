import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Link, Box, Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import timezones from '../utils/timezones'; // Asegúrate de crear este archivo con la lista de zonas horarias

const ClinicSignupPage = () => {
  const [clinicName, setClinicName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [timezone, setTimezone] = useState(null);
  const [adminName, setAdminName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [userIdNumber, setUserIdNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  /**
   * Handles form submission for signup.
   * Sends signup data to the server and processes the response.
   *
   * @param {object} event - The form submission event.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/clinics/register`, {
        clinicName,
        address,
        city,
        country,
        phone,
        timezone: timezone ? timezone.value : '',
        adminName,
        adminEmail,
        adminPassword,
        userIdNumber,
      });
      alert('Signup successful!');
      navigate('/login');
    } catch (error) {
      console.error('Error during signup:', error);
      if (error.response?.data?.errors) {
        setError(error.response.data.errors.map((err) => err.msg).join(', '));
      } else {
        setError(error.response?.data?.message || 'Error during signup. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: 'background.default',
      }}
    >
      <Container maxWidth="sm" sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Clinic Signup
        </Typography>
        <form onSubmit={handleSubmit} method="POST">
          <TextField
            label="Clinic Name"
            value={clinicName}
            onChange={(e) => setClinicName(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <Autocomplete
            options={timezones}
            getOptionLabel={(option) => option.label}
            value={timezone}
            onChange={(event, newValue) => {
              setTimezone(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Timezone" fullWidth margin="normal" required />}
          />
          <TextField
            label="Admin Name"
            value={adminName}
            onChange={(e) => setAdminName(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Admin Email"
            value={adminEmail}
            onChange={(e) => setAdminEmail(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Admin Password"
            type="password"
            value={adminPassword}
            onChange={(e) => setAdminPassword(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="User ID Number"
            value={userIdNumber}
            onChange={(e) => setUserIdNumber(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          {error && (
            <Typography variant="body1" color="error" align="center" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading} sx={{ mt: 2 }}>
            {loading ? 'Signing up...' : 'Signup'}
          </Button>
        </form>
        <Box mt={2} textAlign="center">
          <Typography>
            Already have an account? <Link href="/login">Login</Link>
          </Typography>
          <Typography>
            Forgot your password? <Link href="/reset-password">Reset Password</Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default ClinicSignupPage;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import api from '../api';

const AuthInterceptor = ({ children }) => {
  const { logout, checkAuthStatus } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      await checkAuthStatus();
      
      const token = localStorage.getItem('token');
      if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
    };

    checkAuth();

    // Interceptor para manejar errores de autenticación
    const responseInterceptor = api.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          // Token expirado o inválido
          logout();
          navigate('/login');
        }
        return Promise.reject(error);
      }
    );

    // Limpiar el interceptor al desmontar el componente
    return () => {
      api.interceptors.response.eject(responseInterceptor);
    };
  }, [checkAuthStatus, logout, navigate]);

  // Nota: La autenticación de Google Calendar se maneja en GoogleAuthCallback
  // y no requiere cambios en este interceptor general

  return children;
};

export default AuthInterceptor;
import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Table, TableHead, TableBody, TableRow, TableCell, Box,
  IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Snackbar, Alert
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import api from '../api';
import ConsentTemplateDialog from '../components/ConsentTemplateDialog';

const ConsentTemplatesPage = () => {
  const [templates, setTemplates] = useState([]);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState(null);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await api.get('/consent-templates');
        setTemplates(response.data);
      } catch (error) {
        console.error('Error fetching consent templates:', error);
        showSnackbar('Failed to load templates', 'error');
      }
    };

    fetchTemplates();
  }, []);

  const handleAdd = () => {
    setTemplateToEdit(null);
    setDialogOpen(true);
  };

  const handleEdit = (id) => {
    const template = templates.find(t => t._id === id);
    setTemplateToEdit(template);
    setDialogOpen(true);
  };

  const handleDeleteClick = (id) => {
    setTemplateToDelete(id);
    setConfirmDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await api.delete(`/consent-templates/${templateToDelete}`);
      setTemplates(templates.filter(template => template._id !== templateToDelete));
      showSnackbar('Template deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting consent template:', error);
      showSnackbar('Failed to delete template', 'error');
    } finally {
      setConfirmDialogOpen(false);
      setTemplateToDelete(null);
    }
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
    setTemplateToDelete(null);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleTemplateAdded = (newTemplate) => {
    if (templateToEdit) {
      setTemplates(templates.map(t => t._id === newTemplate._id ? newTemplate : t));
      showSnackbar('Template updated successfully', 'success');
    } else {
      setTemplates([...templates, newTemplate]);
      showSnackbar('Template added successfully', 'success');
    }
    setDialogOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Container>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Informed Consent Templates
      </Typography>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-start' }}>
        <Button variant="contained" startIcon={<Add />} onClick={handleAdd}>
          Add Template
        </Button>
      </Box>
      <Table sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Title</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {templates.map((template) => (
            <TableRow
              key={template._id}
              sx={{
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                  cursor: 'pointer',
                },
              }}
            >
              <TableCell>{template.title}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleEdit(template._id)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleDeleteClick(template._id)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog
        open={isConfirmDialogOpen}
        onClose={handleConfirmDialogClose}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this template? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <ConsentTemplateDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onTemplateAdded={handleTemplateAdded}
        templateToEdit={templateToEdit}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ConsentTemplatesPage;

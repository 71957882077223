import React, { useEffect, useRef, useState } from 'react';
import SignaturePad from 'signature_pad';
import { Button, CircularProgress } from '@mui/material';

const SignaturePadComponent = ({ onSave }) => {
    const canvasRef = useRef(null);
    const signaturePadRef = useRef(null);
    const [loading, setLoading] = useState(false); // Estado para manejar el loader

    useEffect(() => {
        const resizeCanvas = () => {
            const canvas = canvasRef.current;
            const ratio = Math.max(window.devicePixelRatio || 1, 1);
            
            const width = canvas.parentElement.offsetWidth;
            const height = 250;  
            canvas.width = width * ratio;
            canvas.height = height * ratio;
            canvas.style.width = `${width}px`;
            canvas.style.height = `${height}px`;

            const ctx = canvas.getContext("2d");
            ctx.scale(ratio, ratio);

            if (signaturePadRef.current) {
                signaturePadRef.current.clear();
                signaturePadRef.current.off();
            }

            signaturePadRef.current = new SignaturePad(canvas, {
                minWidth: 1,
                maxWidth: 3,
                penColor: 'rgb(0, 0, 0)',
                backgroundColor: 'rgb(255, 255, 255)',
            });
        };

        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);

        return () => {
            window.removeEventListener('resize', resizeCanvas);
            if (signaturePadRef.current) {
                signaturePadRef.current.off();
                signaturePadRef.current = null;
            }
        };
    }, []);

    const handleClear = () => {
        if (signaturePadRef.current) {
            signaturePadRef.current.clear();
        }
    };

    const handleSave = async () => {
        if (signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
            setLoading(true); // Iniciar loader
            const signature = signaturePadRef.current.toDataURL();
            await onSave(signature); // Esperar a que onSave termine
            setLoading(false); // Detener loader después de guardar la firma
        } else {
            alert("Por favor, firme antes de enviar.");
        }
    };

    return (
        <div style={{ border: '1px solid #000', padding: '10px' }}>
            <canvas ref={canvasRef} style={{ width: '100%', height: '250px', display: 'block' }}></canvas>
            <Button 
                variant="outlined" 
                color="secondary" 
                onClick={handleClear} 
                style={{ marginRight: '10px' }}
            >
                Limpiar
            </Button>
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSave} 
                disabled={loading} // Deshabilitar el botón cuando el loader está activo
                startIcon={loading && <CircularProgress size={20} />} // Mostrar loader al inicio del botón
            >
                {loading ? 'Guardando...' : 'Guardar Firma'}
            </Button>
        </div>
    );
};

export default SignaturePadComponent;

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Container, Typography, CircularProgress, Box, Button, 
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Autocomplete, Grid, Switch, FormControlLabel,
  Select, MenuItem, FormControl, InputLabel,
  Link, Menu, IconButton
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import api from '../api';  // Adjust the path according to your structure
import { useAuth } from '../contexts/AuthContext';
import { Link as RouterLink } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import '../components/BigCalendar/calendarStyles.css';

const localizer = dayjsLocalizer(dayjs);
const DnDCalendar = withDragAndDrop(Calendar);

const EventDetailsDialog = ({ event, open, onClose, onStatusChange }) => {
  const [status, setStatus] = useState(event.status);

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);
    try {
      const response = await api.patch(`/appointments/${event.id}`, { status: newStatus });
      if (response.data.googleEventDeleted) {
        console.log('Event deleted from Google Calendar');
      }
      onStatusChange(event.id, newStatus);
    } catch (error) {
      console.error("Error updating appointment status:", error);
      setStatus(event.status); // Revert the status in case of error
    }
  };

  const getPersonLink = () => {
    if (event.patientId) {
      return `/patients/${event.patientId._id}`;
    }
    if (event.leadId) {
      return `/leads/${event.leadId._id}`;
    }
    return '#';
  };

  const getPersonName = () => {
    if (event.patientId) {
      return `${event.patientId.firstName} ${event.patientId.lastName}`;
    }
    if (event.leadId) {
      return `${event.leadId.firstName} ${event.leadId.lastName}`;
    }
    return 'Unknown';
  };

  const getPersonType = () => {
    if (event.patientId) return 'Patient';
    if (event.leadId) return 'Lead';
    return 'Unknown';
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Event Details</DialogTitle>
      <DialogContent>
        <Typography>
          {getPersonType()}:{' '}
          <Link component={RouterLink} to={getPersonLink()}>
            {getPersonName()}
          </Link>
        </Typography>
        <Typography>Treatment: {event.treatment?.name || 'Not specified'}</Typography>
        <Typography>Start: {new Date(event.start).toLocaleString()}</Typography>
        <Typography>End: {new Date(event.end).toLocaleString()}</Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-select-label">Status</InputLabel>
          <Select
            labelId="status-select-label"
            value={status}
            onChange={handleStatusChange}
            label="Status"
          >
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="confirmed">Confirmed</MenuItem>
            <MenuItem value="canceled">Canceled</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const AppointmentsPage = () => {
  const { clinic } = useAuth();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [formData, setFormData] = useState({
    person: null,
    treatment: null,
  });
  const [patients, setPatients] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [syncLoading, setSyncLoading] = useState(false);
  const [syncError, setSyncError] = useState(null);
  const [googleAuthUrl, setGoogleAuthUrl] = useState('');
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);
  const [googleAuthStatus, setGoogleAuthStatus] = useState('unknown'); // 'unknown', 'connected', 'disconnected'
  const location = useLocation();
  const [isLead, setIsLead] = useState(false);
  const [newLeadName, setNewLeadName] = useState('');
  const [newLeadPhone, setNewLeadPhone] = useState('');
  const [patientsAndLeads, setPatientsAndLeads] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const loadInitialData = async () => {
      if (!clinic) return;
      setLoading(true);
      try {
        await Promise.all([
          fetchAppointments(isMounted),
          fetchPatientsAndLeads(isMounted),
          fetchTreatments(isMounted),
          checkGoogleConnection(isMounted)
        ]);
      } catch (error) {
        console.error("Error loading initial data:", error);
        if (isMounted) setError("Error loading initial data");
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    loadInitialData();

    return () => {
      isMounted = false;
    };
  }, [clinic]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const eventId = params.get('eventId');
    if (eventId) {
      const event = events.find(e => e.id === eventId);
      if (event) {
        setSelectedEvent(event);
      }
    }
  }, [location, events]);

  const fetchAppointments = async (isMounted) => {
    try {
      const response = await api.get(`/appointments?clinicId=${clinic._id}`);
      const appointments = response.data.map((appointment) => ({
        id: appointment._id,
        title: appointment.description,
        start: new Date(appointment.startDate),
        end: new Date(appointment.endDate),
        treatment: appointment.treatment,
        patientId: appointment.patientId,
        leadId: appointment.leadId,
        description: appointment.description,
        status: appointment.status,
        className: appointment.status === 'canceled' ? 'canceled' : ''
      }));
      if (isMounted) setEvents(appointments);
    } catch (error) {
      console.error("Error fetching appointments:", error);
      if (isMounted) setError("Error fetching appointments");
    }
  };

  const fetchPatientsAndLeads = async (isMounted) => {
    try {
      const [patientsResponse, leadsResponse] = await Promise.all([
        api.get('/patients'),
        api.get('/leads')
      ]);
      
      const patients = patientsResponse.data.map(patient => ({
        ...patient,
        type: 'patient',
        displayName: `${patient.firstName} ${patient.lastName}`
      }));
      
      const leads = leadsResponse.data.map(lead => ({
        ...lead,
        type: 'lead',
        displayName: lead.firstName && lead.lastName 
          ? `${lead.firstName} ${lead.lastName}`
          : lead.phone || 'Unknown'
      }));

      if (isMounted) setPatientsAndLeads([...patients, ...leads]);
    } catch (error) {
      console.error('Error fetching patients and leads:', error);
      if (isMounted) setError("Error fetching patients and leads");
    }
  };

  const fetchTreatments = async (isMounted) => {
    try {
      const response = await api.get('/treatments');
      if (isMounted) setTreatments(response.data);
    } catch (error) {
      console.error("Error fetching treatments:", error);
      if (isMounted) setError("Error fetching treatments");
    }
  };

  const onEventResize = async ({ event, start, end }) => {
    try {
      const appointmentLength = dayjs(end).diff(dayjs(start), 'minute');

      const updatedEvent = {
        startDate: start,
        endDate: end,
        appointmentLength,
        treatment: event.treatment?._id || event.treatment,
        description: event.title,
      };

      await api.patch(`/appointments/${event.id}`, updatedEvent);

      setEvents((prevEvents) =>
        prevEvents.map((evt) => (evt.id === event.id ? { ...evt, start, end } : evt))
      );
    } catch (error) {
      console.error("Error updating appointment:", error);
    }
  };

  const onEventDrop = async ({ event, start, end }) => {
    try {
      const appointmentLength = dayjs(end).diff(dayjs(start), 'minute');

      const updatedEvent = {
        startDate: start,
        endDate: end,
        appointmentLength,
        treatment: event.treatment?._id || event.treatment,
        description: event.title,
      };

      await api.patch(`/appointments/${event.id}`, updatedEvent);

      setEvents((prevEvents) =>
        prevEvents.map((evt) => (evt.id === event.id ? { ...evt, start, end } : evt))
      );
    } catch (error) {
      console.error("Error updating appointment:", error);
    }
  };

  const handleSelectSlot = (slotInfo) => {
    setSelectedSlot(slotInfo);
    setOpenDialog(true);
  };

  const handleCreateAppointment = async () => {
    if (!validateForm()) {
      alert("All fields are required.");
      return;
    }
    setIsCreating(true);
    try {
      const { start, end } = selectedSlot;
      const appointmentLength = dayjs(end).diff(dayjs(start), 'minute');
      
      let personId, personType, personName;
      if (isLead) {
        // If it's a new lead
        const newLeadData = { 
          firstName: newLeadName,
          phone: newLeadPhone,
          clinic: clinic._id  // Add the clinic ID here
        };
        const newLeadResponse = await api.post('/leads', newLeadData);
        personId = newLeadResponse.data._id;
        personType = 'leadId';
        personName = newLeadName || newLeadPhone;
      } else {
        personId = formData.person._id;
        personType = formData.person.type === 'patient' ? 'patientId' : 'leadId';
        personName = formData.person.firstName && formData.person.lastName
          ? `${formData.person.firstName} ${formData.person.lastName}`
          : formData.person.phone || 'Unnamed person';
      }

      const newAppointment = {
        startDate: start,
        endDate: end,
        appointmentLength,
        treatment: formData.treatment._id,
        description: `${personName} - ${formData.treatment.name}`,
        [personType]: personId,
        clinic: clinic._id  // Changed from clinicId to clinic
      };

      const response = await api.post('/appointments', newAppointment);
      setEvents((prevEvents) => [...prevEvents, {
        id: response.data.appointment._id,
        title: response.data.appointment.description,
        start: new Date(response.data.appointment.startDate),
        end: new Date(response.data.appointment.endDate),
        treatment: response.data.appointment.treatment,
        [personType]: response.data.appointment[personType],
        description: response.data.appointment.description,
        status: response.data.appointment.status,
        className: response.data.appointment.status === 'canceled' ? 'canceled' : ''
      }]);
      setOpenDialog(false);
    } catch (error) {
      console.error('Error creating appointment:', error);
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessages = error.response.data.errors.map(err => err.msg).join(', ');
        alert(`Error creating appointment: ${errorMessages}`);
      } else {
        alert(`Error creating appointment: ${error.message}`);
      }
    } finally {
      setIsCreating(false);
    }
  };

  const handleEventSelect = (event) => {
    navigate(`/appointments?eventId=${event.id}`);
  };

  const handleCancelAppointment = async () => {
    try {
      await api.patch(`/appointments/${selectedEvent.id}`, { status: 'canceled' });
      setEvents((prevEvents) => prevEvents.map(evt => 
        evt.id === selectedEvent.id ? { ...evt, status: 'canceled', className: 'canceled' } : evt
      ));
      setSelectedEvent(null);
    } catch (error) {
      console.error("Error canceling appointment:", error);
    }
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setFormData({ person: null, treatment: null });
  };

  const validateForm = () => {
    if (isLead) {
      return newLeadName && newLeadPhone && formData.treatment;
    } else {
      return formData.person && formData.treatment;
    }
  };

  const eventPropGetter = (event) => {
    let className = 'rbc-event';
    
    if (event.status === 'canceled') {
      className += ' canceled';
    }

    return { className };
  };

  const handleSyncWithGoogle = async () => {
    setSyncLoading(true);
    setSyncError(null);
    try {
      const response = await api.post('/appointments/sync-google-calendar');
      await fetchAppointments(); // Reload appointments after sync
      alert('Sync with Google Calendar completed successfully');
    } catch (error) {
      console.error('Error syncing with Google Calendar:', error);
      if (error.response && error.response.status === 401 && error.response.data.needsAuth) {
        setGoogleAuthStatus('disconnected');
        alert('Google Calendar authorization required. Please connect your account.');
      } else {
        setSyncError('Sync error. Please try again.');
      }
    } finally {
      setSyncLoading(false);
    }
  };

  const checkGoogleConnection = async () => {
    try {
      const response = await api.get('/auth/google/status');
      setGoogleAuthStatus(response.data.isFullyConnected ? 'connected' : 'disconnected');
    } catch (error) {
      console.error('Error checking Google connection:', error);
      setGoogleAuthStatus('error');
    }
  };

  const handleConnectGoogle = async () => {
    try {
      const response = await api.get('/auth/google/auth');
      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error('Error getting Google auth URL:', error);
      setGoogleAuthStatus('error');
    }
  };

  const handleDisconnectGoogle = async () => {
    try {
      await api.post('/auth/google/revoke');
      setGoogleAuthStatus('disconnected');
      alert('Google Calendar disconnected successfully');
    } catch (error) {
      console.error('Error disconnecting Google Calendar:', error);
      setGoogleAuthStatus('error');
      alert('Error disconnecting Google Calendar');
    }
  };

  const handleReactivateAppointment = async () => {
    try {
      await api.patch(`/appointments/${selectedEvent.id}`, { status: 'pending' });
      setEvents((prevEvents) => prevEvents.map(evt => 
        evt.id === selectedEvent.id ? { ...evt, status: 'pending', className: '' } : evt
      ));
      // Close the modal after reactivating the appointment
      setSelectedEvent(null);
    } catch (error) {
      console.error("Error reactivating appointment:", error);
    }
  };

  const formatDate = (date) => {
    return dayjs(date).format('DD-MMM-YYYY. h:mma').replace('.', '').toLowerCase();
  };

  const getPersonType = (event) => {
    if (event.patientId) return 'Patient';
    if (event.leadId) return 'Lead';
    return 'Unknown';
  };

  const getPersonName = (event) => {
    if (event.patientId) {
      return `${event.patientId.firstName} ${event.patientId.lastName}`;
    }
    if (event.leadId) {
      return `${event.leadId.firstName || ''} ${event.leadId.lastName || ''}`.trim() || event.leadId.phone;
    }
    return 'Unknown';
  };

  const getPersonLink = (event) => {
    if (event.patientId) {
      return `/patients/${event.patientId._id}`;
    }
    if (event.leadId) {
      return `/leads/${event.leadId._id}`;
    }
    return '#';
  };

  const handleStatusChange = (eventId, newStatus) => {
    setEvents(prevEvents => 
      prevEvents.map(evt => 
        evt.id === eventId ? { ...evt, status: newStatus, className: newStatus === 'canceled' ? 'canceled' : '' } : evt
      )
    );
  };

  const handleCloseEventDetails = () => {
    setSelectedEvent(null);
    navigate('/appointments');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4">Appointments</Typography>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleMenuOpen}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {googleAuthStatus === 'connected' ? (
            [
              <MenuItem key="sync" onClick={() => { handleSyncWithGoogle(); handleMenuClose(); }}>
                Sync with Google Calendar
              </MenuItem>,
              <MenuItem key="disconnect" onClick={() => { handleDisconnectGoogle(); handleMenuClose(); }}>
                Disconnect Google Calendar
              </MenuItem>
            ]
          ) : googleAuthStatus === 'disconnected' ? (
            <MenuItem onClick={() => { handleConnectGoogle(); handleMenuClose(); }}>
              Connect Google Calendar
            </MenuItem>
          ) : googleAuthStatus === 'error' ? (
            <MenuItem disabled>
              Error with Google Calendar connection
            </MenuItem>
          ) : (
            <MenuItem disabled>
              Loading...
            </MenuItem>
          )}
        </Menu>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ mt: 4 }}>
          <DnDCalendar
              selectable
              defaultDate={dayjs().toDate()}
              defaultView="week"
              events={events}
              localizer={localizer}
              onEventDrop={onEventDrop}
              onEventResize={onEventResize}
              onSelectSlot={handleSelectSlot}
              onSelectEvent={handleEventSelect}
              resizable
              draggableAccessor={() => true}
              style={{ height: "70vh" }}
              eventPropGetter={eventPropGetter}
            />
          </Box>
        </Grid>
      </Grid>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Create New Appointment</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={<Switch checked={isLead} onChange={(e) => setIsLead(e.target.checked)} />}
            label="Is new lead?"
            sx={{ my: 2 }}
          />
          <Grid container spacing={2}>
            {isLead ? (
              <>
                <Grid item xs={12}>
                  <TextField
                    label="New Lead First Name"
                    value={newLeadName}
                    onChange={(e) => setNewLeadName(e.target.value)}
                    fullWidth
                    required
                    sx={{ my: 0 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    country={'co'} // Default country code (Colombia in this case)
                    value={newLeadPhone}
                    onChange={(phone) => setNewLeadPhone(phone)}
                    inputStyle={{
                      width: '100%',
                      height: '56px',
                      fontSize: '16px',
                      paddingLeft: '48px',
                    }}
                    containerStyle={{
                      marginTop: '16px',
                      marginBottom: '8px',
                    }}
                    inputProps={{
                      name: 'phone',
                      required: true,
                      autoFocus: true
                    }}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Autocomplete
                  options={patientsAndLeads}
                  groupBy={(option) => option.type === 'patient' ? 'Patients' : 'Leads'}
                  getOptionLabel={(option) => option.displayName}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option.displayName} ({option.type})
                    </li>
                  )}
                  onChange={(event, newValue) => setFormData({ ...formData, person: newValue })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Patient or Lead"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                      sx={{ my: 0 }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Autocomplete
                options={treatments}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => setFormData({ ...formData, treatment: newValue })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Treatment"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    sx={{ my: 0 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} disabled={isCreating}>Cancel</Button>
          <Button
            onClick={handleCreateAppointment}
            variant="contained"
            color="primary"
            disabled={!validateForm() || isCreating}
          >
            {isCreating ? (
              <>
                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                Creating...
              </>
            ) : (
              'Create'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {selectedEvent && (
        <EventDetailsDialog
          event={selectedEvent}
          open={Boolean(selectedEvent)}
          onClose={handleCloseEventDetails}
          onStatusChange={handleStatusChange}
        />
      )}
    </Container>
  );
};

export default AppointmentsPage;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { 
  Grid, Typography, Button, Container, Table, TableHead, TableBody, TableRow, 
  TableCell, TableContainer, Paper, IconButton, TextField, TablePagination, 
  TableSortLabel, Card, CardContent, Box, CircularProgress, Dialog, 
  DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import AddPatient from '../components/AddPatient';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

const PatientsPage = () => {
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [openAddPatientDialog, setOpenAddPatientDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('firstName');
  const [order, setOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [patientToDelete, setPatientToDelete] = useState(null);
  const { clinic } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPatients = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/patients?clinicId=${clinic._id}`);
        const sortedPatients = response.data.sort((a, b) => 
          a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName)
        );
        setPatients(sortedPatients);
        setFilteredPatients(sortedPatients);
      } catch (error) {
        console.error('Error fetching patients:', error);
      } finally {
        setLoading(false);
      }
    };

    if (clinic) {
      fetchPatients();
    }
  }, [clinic]);

  useEffect(() => {
    const filtered = patients.filter(patient => 
      patient.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      patient.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPatients(filtered);
    setPage(0);
  }, [searchTerm, patients]);

  const handleAddPatientClick = () => {
    setOpenAddPatientDialog(true);
  };

  const handleAddPatientClose = () => {
    setOpenAddPatientDialog(false);
  };

  const handlePatientAdded = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/patients?clinicId=${clinic._id}`);
      const sortedPatients = response.data.sort((a, b) => 
        a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName)
      );
      setPatients(sortedPatients);
      setFilteredPatients(sortedPatients);
    } catch (error) {
      console.error('Error fetching patients:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (id) => {
    navigate(`/patients/${id}`);
  };

  const handleDeletePatient = async (id) => {
    try {
      await api.delete(`/patients/${id}`);
      setPatients((prev) => prev.filter((patient) => patient._id !== id).sort((a, b) => 
        a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName)
      ));
      setFilteredPatients((prev) => prev.filter((patient) => patient._id !== id).sort((a, b) => 
        a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName)
      ));
    } catch (error) {
      console.error('Error deleting patient:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    
    const sortedPatients = [...filteredPatients].sort((a, b) => {
      if (a[property] < b[property]) return isAsc ? -1 : 1;
      if (a[property] > b[property]) return isAsc ? 1 : -1;
      return 0;
    });
    
    setFilteredPatients(sortedPatients);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDeleteClick = (event, patient) => {
    event.stopPropagation();
    setPatientToDelete(patient);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (patientToDelete) {
      handleDeletePatient(patientToDelete._id);
    }
    setDeleteConfirmOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Patients</Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleAddPatientClick}
          startIcon={<AddIcon />}
        >
          Add Patient
        </Button>
      </Box>

      <Card>
        <CardContent>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search patients..."
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
          </Box>

          {loading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {['firstName', 'lastName', 'email', 'phone', 'address'].map((column) => (
                        <TableCell key={column}>
                          <TableSortLabel
                            active={orderBy === column}
                            direction={orderBy === column ? order : 'asc'}
                            onClick={() => handleRequestSort(column)}
                          >
                            {column.charAt(0).toUpperCase() + column.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredPatients
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((patient) => (
                        <TableRow
                          key={patient._id}
                          hover
                          onClick={() => handleRowClick(patient._id)}
                        >
                          <TableCell>{patient.firstName}</TableCell>
                          <TableCell>{patient.lastName}</TableCell>
                          <TableCell>{patient.email}</TableCell>
                          <TableCell>{patient.phone}</TableCell>
                          <TableCell>{patient.address}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={(e) => handleDeleteClick(e, patient)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredPatients.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </CardContent>
      </Card>

      {openAddPatientDialog && (
        <AddPatient onClose={handleAddPatientClose} onPatientAdded={handlePatientAdded} />
      )}

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this patient?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PatientsPage;

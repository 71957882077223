import React, { useEffect, useState } from 'react';
import api from '../api'; // Usar la instancia configurada de Axios
import { Container, Typography, Table, TableHead, TableBody, TableRow, TableCell, Box, Grid, Chip } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

function InventoryRecordsPage() {
  // Estado para almacenar los registros de inventario
  const [inventoryRecords, setInventoryRecords] = useState([]);

  // Obtener el objeto de usuario desde el contexto de autenticación
  const { user } = useAuth();

  // useEffect para obtener los registros de inventario cuando cambie el usuario
  useEffect(() => {
    const fetchInventoryRecords = async () => {
      try {
        // Obtener los registros de inventario mediante la API con el clinicId del usuario
        const response = await api.get('/inventory-records', {
          params: { clinicId: user.clinicId }, // Enviar clinicId como parámetro
        });
        // Almacenar los registros obtenidos en el estado
        setInventoryRecords(response.data);
      } catch (error) {
        console.error('Error fetching inventory records:', error);
      }
    };

    if (user) {
      fetchInventoryRecords();
    }
  }, [user]);

  return (
    <Container maxWidth="xl" sx={{ mb: 2 }}>
      {/* Encabezado de la página con título */}
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Inventory Records
          </Typography>
        </Grid>
      </Grid>

      {/* Tabla de registros de inventario */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ 
            border: '1px solid #ddd', // Aplicar borde alrededor del Box
            borderRadius: 4, // Radio de 4 para las esquinas del borde
            overflow: 'auto' // Prevenir que el borde redondeado sea cortado
          }}>
            <Table
              sx={{
                minWidth: 650,
                borderCollapse: 'separate', // Mantener separación entre celdas
                borderSpacing: 0, // Eliminar espaciado entre celdas para bordes claros
                overflow: 'auto'
              }}
              aria-label="inventory table"
            >
              <TableHead>
                <TableRow>
                  {/* Encabezados de las columnas */}
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: '1px solid #ddd' }}>Product</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: '1px solid #ddd' }}>Quantity</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: '1px solid #ddd' }}>Movement Type</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: '1px solid #ddd' }}>Date</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: '1px solid #ddd' }}>Remarks</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', borderBottom: '1px solid #ddd' }}>Total Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Mapear registros de inventario para llenar la tabla */}
                {inventoryRecords.map((inventoryRecord) => (
                  <TableRow
                    key={inventoryRecord._id}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#f5f5f5', // Cambiar color de fondo al pasar el cursor
                        cursor: 'pointer',
                      },
                    }}
                  >
                    {/* Celdas con datos de cada registro */}
                    <TableCell sx={{ borderBottom: '1px solid #ddd' }}>{inventoryRecord.product.name}</TableCell>
                    <TableCell sx={{ borderBottom: '1px solid #ddd' }}>{inventoryRecord.quantity}</TableCell>
                    <TableCell sx={{ borderBottom: '1px solid #ddd' }}>
                      {/* Mostrar "Movement Type" como un Chip */}
                      <Chip 
                        label={inventoryRecord.movementType} 
                        size="small" 
                        variant="outlined" // Usar variante outlined
                        sx={{
                          borderColor: inventoryRecord.movementType === 'Income' ? 'green' : 'red', // Cambiar el color del borde
                          color: inventoryRecord.movementType === 'Income' ? 'green' : 'red', // Cambiar el color del texto
                          backgroundColor: 'transparent' // Fondo transparente para mantener el estilo outlined
                        }} 
                      />
                    </TableCell>
                    <TableCell sx={{ borderBottom: '1px solid #ddd' }}>{new Date(inventoryRecord.date).toLocaleDateString()}</TableCell>
                    <TableCell sx={{ borderBottom: '1px solid #ddd' }}>{inventoryRecord.remarks}</TableCell>
                    <TableCell sx={{ borderBottom: '1px solid #ddd' }}>{inventoryRecord.totalQuantity}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default InventoryRecordsPage;

import React, { useEffect, useState } from 'react';
import api from '../api';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Chip,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * Función utilitaria para formatear la fecha
 * @param {string} dateString - Fecha en formato string
 * @returns {string} - Fecha formateada
 */
const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString();
};

/**
 * Función para renderizar el estado del consentimiento
 * @param {object} consent - Objeto de consentimiento
 * @returns {JSX.Element} - Chip que representa el estado de la firma
 */
const renderStatusChip = (consent, handleDelete) => {
  const isSigned = !!consent.signatureUrl;
  const label = isSigned ? 'Signed' : 'Pending';
  const color = isSigned ? 'green' : 'orange';
  return (
    <>
      <Chip
        label={label}
        sx={{ borderColor: color, color, fontSize: '14px', padding: '4px 8px' }}
        variant="outlined"
      />
      {!isSigned && (
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(consent._id);
          }}
          sx={{ marginLeft: 2 }}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </>
  );
};

const ConsentTable = ({ patientId }) => {
  const [consents, setConsents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchConsents = async () => {
      try {
        const response = await api.get(`/informed-consents`, { params: { patientId } });
        setConsents(response.data);
      } catch (error) {
        console.error('Error fetching informed consents:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConsents();
  }, [patientId]);

  const handleRowClick = async (consent) => {
    try {
      const response = await api.get(`/informed-consents/${consent._id}/presigned-url`);
      const { url } = response.data;
      window.open(url, '_blank'); // Abrir la URL en una nueva pestaña
    } catch (error) {
      console.error('Error getting presigned URL:', error);
    }
  };

  const handleDelete = async (consentId) => {
    try {
      await api.delete(`/informed-consents/${consentId}`);
      setConsents(consents.filter((consent) => consent._id !== consentId));
    } catch (error) {
      console.error('Error deleting informed consent:', error);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper elevation={3}>
      <TableContainer>
        <Typography variant="h6" gutterBottom component="div" sx={{ p: 2 }}>
          Informed Consents
        </Typography>
        {consents.length === 0 ? (
          <Typography sx={{ p: 2 }}>No informed consents found.</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Treatment</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Date Sent</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Signature Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {consents.map((consent) => (
                <TableRow
                  key={consent._id}
                  onClick={() => handleRowClick(consent)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    },
                  }}
                >
                  <TableCell>{consent.treatmentName || consent.treatmentId?.name || 'Unknown'}</TableCell>
                  <TableCell>{formatDate(consent.createdAt)}</TableCell>
                  <TableCell>{renderStatusChip(consent, handleDelete)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Paper>
  );
};

export default ConsentTable;

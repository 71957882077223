import React, { useState, useMemo, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableSortLabel,
  Button,
  Grid,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuth } from '../contexts/AuthContext'; // Asegúrate de que la ruta sea correcta

const ClinicalHistoryList = ({ patientId, histories = [], onHistoryAdded, refreshHistories, isLoading }) => {
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [orderBy, setOrderBy] = useState('dateTime');
  const [order, setOrder] = useState('desc');
  const { clinic } = useAuth();

  useEffect(() => {
    if (refreshHistories) {
      refreshHistories();
    }
  }, [refreshHistories]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRowClick = (history) => {
    setSelectedHistory(history);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const sortedHistories = useMemo(() => {
    const comparator = (a, b) => {
      if (orderBy === 'dateTime') {
        return order === 'desc'
          ? new Date(b.dateTime) - new Date(a.dateTime)
          : new Date(a.dateTime) - new Date(b.dateTime);
      }
      const aValue = a[orderBy] || '';
      const bValue = b[orderBy] || '';
      return order === 'desc'
        ? bValue.localeCompare(aValue)
        : aValue.localeCompare(bValue);
    };

    return [...histories].sort(comparator);
  }, [histories, order, orderBy]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Paper elevation={3}>
      <TableContainer>
        <Typography variant="h6" gutterBottom component="div" sx={{ p: 2 }}>
          Clinical History
        </Typography>
        {Array.isArray(sortedHistories) && sortedHistories.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'dateTime'}
                    direction={orderBy === 'dateTime' ? order : 'asc'}
                    onClick={() => handleRequestSort('dateTime')}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'reasonForConsultation'}
                    direction={orderBy === 'reasonForConsultation' ? order : 'asc'}
                    onClick={() => handleRequestSort('reasonForConsultation')}
                  >
                    Reason for Consultation
                  </TableSortLabel>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedHistories.map((history) => (
                <TableRow 
                  key={history._id}
                  onClick={() => handleRowClick(history)}
                  hover
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{new Date(history.dateTime).toLocaleString()}</TableCell>
                  <TableCell>{history.reasonForConsultation}</TableCell>
                  <TableCell>
                    <IconButton 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRowClick(history);
                      }}
                      size="small"
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography sx={{ p: 2 }}>No clinical histories available.</Typography>
        )}
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          Clinical History Details
        </DialogTitle>
        <DialogContent>
          {selectedHistory && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">General Information</Typography>
                <Typography>Date: {new Date(selectedHistory.dateTime).toLocaleString()}</Typography>
                <Typography>Reason for Consultation: {selectedHistory.reasonForConsultation}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Medical History</Typography>
                <Typography>Pathological: {selectedHistory.pathological}</Typography>
                <Typography>Pharmacological: {selectedHistory.pharmacological}</Typography>
                <Typography>Surgical: {selectedHistory.surgical}</Typography>
                <Typography>Allergic: {selectedHistory.allergic}</Typography>
                <Typography>Toxicological: {selectedHistory.toxicological}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Physical Examination</Typography>
                <Typography>Weight: {selectedHistory.weight} kg</Typography>
                <Typography>Height: {selectedHistory.height} cm</Typography>
                <Typography>BMI: {selectedHistory.bmi}</Typography>
                <Typography>Skin Type: {selectedHistory.skinType}</Typography>
                <Typography>Fitzpatrick Scale: {selectedHistory.fitzpatrickScale}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Diagnosis and Treatment</Typography>
                <Typography>Diagnosis: {selectedHistory.diagnosis}</Typography>
                <Typography>Treatment Description: {selectedHistory.treatmentDescription}</Typography>
                <Typography>Treatment Notes: {selectedHistory.treatmentNotes}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Treatments Performed</Typography>
                {selectedHistory.treatmentsPerformed && selectedHistory.treatmentsPerformed.length > 0 ? (
                  selectedHistory.treatmentsPerformed.map((treatment, index) => (
                    <Typography key={index}>
                      Treatment: {treatment.treatment.name}, Amount: ${treatment.amount}
                    </Typography>
                  ))
                ) : (
                  <Typography>No treatments performed</Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Consumables Used</Typography>
                {selectedHistory.consumablesUsed && selectedHistory.consumablesUsed.length > 0 ? (
                  selectedHistory.consumablesUsed.map((consumable, index) => (
                    <Typography key={index}>
                      Product: {consumable.product.name}, Quantity: {consumable.quantityUsed}
                    </Typography>
                  ))
                ) : (
                  <Typography>No consumables used</Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Payments</Typography>
                {selectedHistory.payments && selectedHistory.payments.length > 0 ? (
                  selectedHistory.payments.map((payment, index) => (
                    <Typography key={index}>
                      Payment ID: {payment._id}, Amount: ${payment.amount}
                    </Typography>
                  ))
                ) : (
                  <Typography>No payments recorded</Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Financial Information</Typography>
                <Typography>Invoice Amount: ${selectedHistory.invoiceAmount}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Additional Information</Typography>
                <Typography>Observations: {selectedHistory.observations}</Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ClinicalHistoryList;
import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip } from '@mui/material';
import api from '../api';
import { useAuth } from '../contexts/AuthContext';

function InventoryRecordsTable() {
  const [inventoryRecords, setInventoryRecords] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchInventoryRecords = async () => {
      try {
        const response = await api.get('/inventory-records', {
          params: { clinicId: user.clinicId },
        });
        if (Array.isArray(response.data)) {
          setInventoryRecords(response.data);
        } else {
          console.error('Unexpected data structure:', response.data);
          setInventoryRecords([]);
        }
      } catch (error) {
        console.error('Error fetching inventory records:', error);
        setInventoryRecords([]);
      }
    };
  
    if (user) {
      fetchInventoryRecords();
    }
  }, [user]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Quantity (Purchase Units)</TableCell>
            <TableCell>Quantity (Usage Units)</TableCell>
            <TableCell>Movement Type</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Batch</TableCell>
            <TableCell>Expiration Date</TableCell>
            <TableCell>Remaining Stock</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inventoryRecords.map((record) => (
            <TableRow key={record._id}>
              <TableCell>{record.product?.name || 'N/A'}</TableCell>
              <TableCell>{record.quantityPurchaseUnits} {record.product?.purchaseUnit?.unitMeasure}</TableCell>
              <TableCell>{record.quantityUsageUnits} {record.product?.usageUnit?.unitMeasure}</TableCell>
              <TableCell>
                <Chip 
                  label={record.movementType} 
                  color={record.movementType === 'Income' ? 'success' : 'error'}
                  variant="outlined"
                />
              </TableCell>
              <TableCell>{new Date(record.date).toLocaleDateString()}</TableCell>
              <TableCell>{record.batch || 'N/A'}</TableCell>
              <TableCell>{record.expirationDate ? new Date(record.expirationDate).toLocaleDateString() : 'N/A'}</TableCell>
              <TableCell>{record.remainingQuantityUsageUnits} {record.product?.usageUnit?.unitMeasure}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default InventoryRecordsTable;
import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import MentionList from './MentionList.jsx';

export default {
  items: ({ query }) => {
    const placeholders = [
      { id: 'patient_name', label: 'Patient Name' },
      { id: 'patient_id', label: 'Patient Id' },
      { id: 'treatment_name', label: 'Treatment Name' },
      { id: 'doctor_name', label: 'Doctor Name' },
      { id: 'doctor_id', label: 'Doctor Id' },
      { id: 'clinic_name', label: 'Clinic Name' },
      { id: 'product_name', label: 'Product Name' },
      { id: 'date', label: 'Current Date' },
    ];

    return placeholders.filter(item => item.label.toLowerCase().includes(query.toLowerCase()));
  },

  render: () => {
    let component;
    let popup;

    return {
      onStart: (props) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
          // Ajustamos el estilo del popup
          arrow: false,
          theme: 'light-border',  // Usamos un tema ligero para el popup
          offset: [0, 8],  // Ajustamos el desplazamiento del popup
        });
      },

      onUpdate(props) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide();
          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};

const timezones = [
    // North America
    { value: 'America/New_York', label: 'Eastern Time (ET)' },
    { value: 'America/Chicago', label: 'Central Time (CT)' },
    { value: 'America/Denver', label: 'Mountain Time (MT)' },
    { value: 'America/Los_Angeles', label: 'Pacific Time (PT)' },
    { value: 'America/Anchorage', label: 'Alaska Time (AKT)' },
    { value: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Time (HAT)' },
    { value: 'America/Phoenix', label: 'Mountain Standard Time (MST)' },
    { value: 'America/Toronto', label: 'Eastern Time - Toronto (ET)' },
    { value: 'America/Vancouver', label: 'Pacific Time - Vancouver (PT)' },
    { value: 'America/Edmonton', label: 'Mountain Time - Edmonton (MT)' },
    { value: 'America/Winnipeg', label: 'Central Time - Winnipeg (CT)' },
    { value: 'America/Halifax', label: 'Atlantic Time (AT)' },
    { value: 'America/St_Johns', label: 'Newfoundland Time (NT)' },
    { value: 'America/Mexico_City', label: 'Central Time - Mexico City (CT)' },
    { value: 'America/Tijuana', label: 'Pacific Time - Tijuana (PT)' },
    { value: 'America/Monterrey', label: 'Central Time - Monterrey (CT)' },
    { value: 'America/Guatemala', label: 'Central Time - Guatemala (CT)' },
    { value: 'America/El_Salvador', label: 'Central Time - El Salvador (CT)' },
    { value: 'America/Managua', label: 'Central Time - Nicaragua (CT)' },
    { value: 'America/Costa_Rica', label: 'Central Time - Costa Rica (CT)' },
    { value: 'America/Panama', label: 'Eastern Time - Panama (ET)' },
    { value: 'America/Havana', label: 'Cuba Time (CT)' },
    { value: 'America/Port-au-Prince', label: 'Eastern Time - Haiti (ET)' },
    { value: 'America/Santo_Domingo', label: 'Atlantic Time - Dominican Republic (AT)' },
    { value: 'America/Puerto_Rico', label: 'Atlantic Time - Puerto Rico (AT)' },
    { value: 'America/Jamaica', label: 'Eastern Time - Jamaica (ET)' },
  
    // South America
    { value: 'America/Sao_Paulo', label: 'Brasilia Time (BRT)' },
    { value: 'America/Buenos_Aires', label: 'Argentina Time (ART)' },
    { value: 'America/Lima', label: 'Peru Time (PET)' },
    { value: 'America/Bogota', label: 'Colombia Time (COT)' },
    { value: 'America/Caracas', label: 'Venezuela Time (VET)' },
    { value: 'America/Santiago', label: 'Chile Standard Time (CLT)' },
    { value: 'America/La_Paz', label: 'Bolivia Time (BOT)' },
    { value: 'America/Guayaquil', label: 'Ecuador Time (ECT)' },
    { value: 'America/Asuncion', label: 'Paraguay Time (PYT)' },
    { value: 'America/Montevideo', label: 'Uruguay Time (UYT)' },
    { value: 'America/Cayenne', label: 'French Guiana Time (GFT)' },
    { value: 'America/Paramaribo', label: 'Suriname Time (SRT)' },
    { value: 'America/Guyana', label: 'Guyana Time (GYT)' },
  
    // Europe
    { value: 'Europe/London', label: 'British Summer Time (BST)' },
    { value: 'Europe/Dublin', label: 'Irish Standard Time (IST)' },
    { value: 'Europe/Lisbon', label: 'Western European Summer Time (WEST)' },
    { value: 'Europe/Paris', label: 'Central European Summer Time (CEST)' },
    { value: 'Europe/Berlin', label: 'Central European Summer Time (CEST)' },
    { value: 'Europe/Rome', label: 'Central European Summer Time (CEST)' },
    { value: 'Europe/Madrid', label: 'Central European Summer Time (CEST)' },
    { value: 'Europe/Amsterdam', label: 'Central European Summer Time (CEST)' },
    { value: 'Europe/Brussels', label: 'Central European Summer Time (CEST)' },
    { value: 'Europe/Vienna', label: 'Central European Summer Time (CEST)' },
    { value: 'Europe/Stockholm', label: 'Central European Summer Time (CEST)' },
    { value: 'Europe/Copenhagen', label: 'Central European Summer Time (CEST)' },
    { value: 'Europe/Oslo', label: 'Central European Summer Time (CEST)' },
    { value: 'Europe/Warsaw', label: 'Central European Summer Time (CEST)' },
    { value: 'Europe/Budapest', label: 'Central European Summer Time (CEST)' },
    { value: 'Europe/Athens', label: 'Eastern European Summer Time (EEST)' },
    { value: 'Europe/Bucharest', label: 'Eastern European Summer Time (EEST)' },
    { value: 'Europe/Helsinki', label: 'Eastern European Summer Time (EEST)' },
    { value: 'Europe/Kiev', label: 'Eastern European Summer Time (EEST)' },
    { value: 'Europe/Moscow', label: 'Moscow Standard Time (MSK)' },
  
    // Asia
    { value: 'Asia/Tokyo', label: 'Japan Standard Time (JST)' },
    { value: 'Asia/Shanghai', label: 'China Standard Time (CST)' },
    { value: 'Asia/Dubai', label: 'Gulf Standard Time (GST)' },
    { value: 'Asia/Kolkata', label: 'India Standard Time (IST)' },
  
    // Australia and Pacific
    { value: 'Australia/Sydney', label: 'Australian Eastern Standard Time (AEST)' },
    { value: 'Pacific/Auckland', label: 'New Zealand Standard Time (NZST)' },
  
    // Africa
    { value: 'Africa/Cairo', label: 'Eastern European Summer Time (EEST)' },
    { value: 'Africa/Johannesburg', label: 'South Africa Standard Time (SAST)' },
];

export default timezones;
import React from 'react';
import { Grid, TextField, Box, Typography, Container } from '@mui/material';

const MedicalHistorySection = ({ formData = {}, handleChange }) => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom align="center">
          General
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Reason for consultation *"
              name="reasonForConsultation"
              value={formData.reasonForConsultation || ''}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Date & Time *"
              name="dateTime"
              type="datetime-local"
              value={formData.dateTime || ''}
              onChange={handleChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom align="center">
            Medical History
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Pathological *"
                name="pathological"
                value={formData.pathological || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Pharmacological"
                name="pharmacological"
                value={formData.pharmacological || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Surgical"
                name="surgical"
                value={formData.surgical || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Allergic"
                name="allergic"
                value={formData.allergic || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Toxicological"
                name="toxicological"
                value={formData.toxicological || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default MedicalHistorySection;

import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, MenuItem, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemIcon, ListItemText, Divider
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PaidIcon from '@mui/icons-material/Paid';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PPEFormDialog from '../components/PPEFormDialog';
import ShortTermLoanDialog from '../components/ShortTermLoanDialog'; 
import LongTermLoanDialog from '../components/LongTermLoanDialog'; 
import ShareCapitalDialog from '../components/ShareCapitalDialog'; 
import RetainedEarningsDialog from '../components/RetainedEarningsDialog'; 
import ExpenseDialog from '../components/ExpenseDialog'; 
import PayablePaymentDialog from '../components/PayablePaymentDialog'; // Importa el componente PayablePaymentDialog
import api from '../api'; 

const TransactionsPage = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [referenceType, setReferenceType] = useState('');
  const [referenceTypes, setReferenceTypes] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [ppeDialogOpen, setPPEDialogOpen] = useState(false);
  const [shortTermLoanDialogOpen, setShortTermLoanDialogOpen] = useState(false);
  const [longTermLoanDialogOpen, setLongTermLoanDialogOpen] = useState(false);
  const [shareCapitalDialogOpen, setShareCapitalDialogOpen] = useState(false);
  const [retainedEarningsDialogOpen, setRetainedEarningsDialogOpen] = useState(false);
  const [expenseDialogOpen, setExpenseDialogOpen] = useState(false);
  const [payablePaymentDialogOpen, setPayablePaymentDialogOpen] = useState(false); // Estado para PayablePaymentDialog

  const fetchTransactions = async () => {
    try {
      const response = await api.get('/financialTransactions');
      setTransactions(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  useEffect(() => {
    const fetchReferenceTypes = async () => {
      try {
        const response = await api.get('/financialTransactions/reference-types');
        setReferenceTypes(response.data);
      } catch (error) {
        console.error('Error fetching reference types:', error);
      }
    };

    fetchReferenceTypes();
  }, []);

  const handleDateChange = (newValue) => {
    setDateRange(newValue);
  };

  const filteredTransactions = transactions.filter((transaction) => {
    const date = new Date(transaction.date);
    const startDate = dateRange[0] ? new Date(dateRange[0]) : null;
    const endDate = dateRange[1] ? new Date(dateRange[1]) : null;

    return (
      (!startDate || date >= startDate) &&
      (!endDate || date <= endDate) &&
      (referenceType ? transaction.referenceType === referenceType : true)
    );
  });

  const actions = [
    {
      icon: <ApartmentIcon />,
      name: 'Property, Plant, and Equipment',
      onClick: () => {
        setDialogOpen(false);
        setPPEDialogOpen(true);
      }
    },
    {
      icon: <PaidIcon />,
      name: 'Short-term Loans',
      onClick: () => {
        setDialogOpen(false);
        setShortTermLoanDialogOpen(true);
      }
    },
    {
      icon: <PaidIcon />,
      name: 'Long-term Loans',
      onClick: () => {
        setDialogOpen(false);
        setLongTermLoanDialogOpen(true);
      }
    },
    {
      icon: <AccountBoxIcon />,
      name: 'Share Capital',
      onClick: () => {
        setDialogOpen(false);
        setShareCapitalDialogOpen(true);
      }
    },
    {
      icon: <AccountBalanceWalletIcon />,
      name: 'Retained Earnings',
      onClick: () => {
        setDialogOpen(false);
        setRetainedEarningsDialogOpen(true);
      }
    },
    {
      icon: <ShoppingCartIcon />,
      name: 'Expense',
      onClick: () => {
        setDialogOpen(false);
        setExpenseDialogOpen(true);
      }
    },
    {
      icon: <AccountBalanceIcon />,
      name: 'Pay Accounts Payable',
      onClick: () => {
        setDialogOpen(false);
        setPayablePaymentDialogOpen(true);
      }
    },
  ];

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handlePPEDialogClose = () => {
    setPPEDialogOpen(false);
  };

  const handleShortTermLoanDialogClose = () => {
    setShortTermLoanDialogOpen(false);
  };

  const handleLongTermLoanDialogClose = () => {
    setLongTermLoanDialogOpen(false);
  };

  const handleShareCapitalDialogClose = () => {
    setShareCapitalDialogOpen(false);
  };

  const handleRetainedEarningsDialogClose = () => {
    setRetainedEarningsDialogOpen(false);
  };

  const handleExpenseDialogClose = () => {
    setExpenseDialogOpen(false);
  };

  const handlePayablePaymentDialogClose = () => {
    setPayablePaymentDialogOpen(false);
  };

  const handlePPEAdded = (newPPE) => {
    console.log('New PPE added:', newPPE);
    fetchTransactions();
  };

  const handleShortTermLoanAdded = (newLoan) => {
    console.log('New short-term loan added:', newLoan);
    fetchTransactions();
  };

  const handleShortTermLoanPaid = (newPayment) => {
    console.log('Short-term loan payment recorded:', newPayment);
    fetchTransactions();
  };

  const handleLongTermLoanAdded = (newLoan) => {
    console.log('New long-term loan added:', newLoan);
    fetchTransactions();
  };

  const handleLongTermLoanPaid = (newPayment) => {
    console.log('Long-term loan payment recorded:', newPayment);
    fetchTransactions();
  };

  const handleShareCapitalAdded = (newShareCapital) => {
    console.log('New share capital added:', newShareCapital);
    fetchTransactions();
  };

  const handleRetainedEarningsAdded = (newRetainedEarnings) => {
    console.log('New retained earnings added:', newRetainedEarnings);
    fetchTransactions();
  };

  const handleExpenseAdded = (newExpense) => {
    console.log('New expense added:', newExpense);
    fetchTransactions();
  };

  const handlePayablePaymentAdded = (newPayment) => {
    console.log('New payable payment recorded:', newPayment);
    fetchTransactions();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <Typography variant="h2">Transactions</Typography>
        <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 2 }}>
          <Button variant="contained" color="primary" onClick={handleDialogOpen}>
            Add Transaction
          </Button>
          <DatePicker
            label="Start Date"
            value={dateRange[0]}
            onChange={(newValue) => handleDateChange([newValue, dateRange[1]])}
            slotProps={{ textField: { variant: 'outlined' } }}
          />
          <DatePicker
            label="End Date"
            value={dateRange[1]}
            onChange={(newValue) => handleDateChange([dateRange[0], newValue])}
            slotProps={{ textField: { variant: 'outlined' } }}
          />
          <TextField
            select
            label="Reference Type"
            value={referenceType}
            onChange={(e) => setReferenceType(e.target.value)}
            variant="outlined"
            sx={{ width: 200 }}
          >
            <MenuItem value="">All</MenuItem>
            {referenceTypes.map((type) => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Account Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Amount</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Reference Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && filteredTransactions.length > 0 ? (
                filteredTransactions.map((transaction) => (
                  <TableRow key={transaction._id}>
                    <TableCell>{transaction.account ? transaction.account.name : 'N/A'}</TableCell>
                    <TableCell>{transaction.type}</TableCell>
                    <TableCell>{`$${transaction.amount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0, })}`}</TableCell>
                    <TableCell>{transaction.description}</TableCell>
                    <TableCell>{new Date(transaction.date).toLocaleDateString()}</TableCell>
                    <TableCell>{transaction.referenceType}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>{isLoading ? 'Loading...' : 'No transactions found'}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Add Transaction</DialogTitle>
          <DialogContent>
            <List>
              {actions.slice(0, 6).map((action) => (
                <ListItem button key={action.name} onClick={action.onClick}>
                  <ListItemIcon>{action.icon}</ListItemIcon>
                  <ListItemText primary={action.name} />
                </ListItem>
              ))}
              <Divider />
              <ListItem button key={actions[6].name} onClick={actions[6].onClick}>
                <ListItemIcon>{actions[6].icon}</ListItemIcon>
                <ListItemText primary={actions[6].name} />
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <PPEFormDialog
          open={ppeDialogOpen}
          onClose={handlePPEDialogClose}
          onPPEAdded={handlePPEAdded}
        />

        <ShortTermLoanDialog
          open={shortTermLoanDialogOpen}
          onClose={handleShortTermLoanDialogClose}
          onLoanAdded={handleShortTermLoanAdded}
          onLoanPaid={handleShortTermLoanPaid}
        />

        <LongTermLoanDialog
          open={longTermLoanDialogOpen}
          onClose={handleLongTermLoanDialogClose}
          onLoanAdded={handleLongTermLoanAdded}
          onLoanPaid={handleLongTermLoanPaid}
        />

        <ShareCapitalDialog
          open={shareCapitalDialogOpen}
          onClose={handleShareCapitalDialogClose}
          onShareCapitalAdded={handleShareCapitalAdded}
        />

        <RetainedEarningsDialog
          open={retainedEarningsDialogOpen}
          onClose={handleRetainedEarningsDialogClose}
          onRetainedEarningsAdded={handleRetainedEarningsAdded}
        />

        <ExpenseDialog
          open={expenseDialogOpen}
          onClose={handleExpenseDialogClose}
          onExpenseAdded={handleExpenseAdded}
        />

        <PayablePaymentDialog
          open={payablePaymentDialogOpen}
          onClose={handlePayablePaymentDialogClose}
          onPaymentAdded={handlePayablePaymentAdded}
        />
      </Container>
    </LocalizationProvider>
  );
};

export default TransactionsPage;

import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import api from '../api';

const PLPage = () => {
  const currentMonthStart = dayjs().startOf('month').toDate();
  const currentMonthEnd = dayjs().endOf('month').toDate();

  const [startDate, setStartDate] = useState(currentMonthStart);
  const [endDate, setEndDate] = useState(currentMonthEnd);
  const [data, setData] = useState({
    sales: [],
    costOfSales: [],
    operatingExpenses: [],
    administrativeExpenses: [],
    interestExpenses: 0,
    taxes: 0,
    depreciation: 0,
    grossProfit: 0,
    operatingProfit: 0,
    ebitda: 0,
    netProfit: 0
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.post('/financialTransactions/pl', {
          startDate: dayjs(startDate).toISOString(),
          endDate: dayjs(endDate).toISOString()
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching P&L data:', error);
      }
    };

    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate]);

  const formatAmount = (amount) => {
    if (isNaN(amount) || amount === null || amount === undefined) {
      return '$0';
    }
    const formatted = amount.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return amount < 0 ? `($${formatted})` : `$${formatted}`;
  };

  const renderRow = (category, amount, isIndented = false, isHeader = false, isSubtotal = false) => (
    <TableRow key={category} sx={{ backgroundColor: isSubtotal ? '#f5f5f5' : 'transparent', fontWeight: isSubtotal ? 'bold' : 'normal' }}>
      <TableCell sx={{ paddingLeft: isIndented ? 4 : isHeader || isSubtotal ? 2 : 0, fontWeight: isHeader || isSubtotal ? 'bold' : 'normal' }}>{category}</TableCell>
      <TableCell sx={{ fontWeight: isSubtotal ? 'bold' : 'normal' }}>{isHeader ? '' : formatAmount(amount)}</TableCell>
    </TableRow>
  );

  const calculateTotal = (items) => (items || []).reduce((total, item) => total + item.amount, 0);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <Typography variant="h2">P&L</Typography>
        <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 2 }}>
          <DatePicker
            label="Start Date"
            value={dayjs(startDate)}
            onChange={(newValue) => setStartDate(newValue.toDate())}
            slotProps={{ textField: { variant: 'outlined' } }}
          />
          <DatePicker
            label="End Date"
            value={dayjs(endDate)}
            onChange={(newValue) => setEndDate(newValue.toDate())}
            slotProps={{ textField: { variant: 'outlined' } }}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Category</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderRow('Sales', 0, false, true)}
                {(data.sales || []).map(sale => renderRow(sale.category, sale.amount, true))}
                {renderRow('Total sales', calculateTotal(data.sales), false, false, true)}

                {renderRow('Cost of sales', 0, false, true)}
                {(data.costOfSales || []).map(cost => renderRow(cost.category, -cost.amount, true))}
                {renderRow('Total cost of sales', -calculateTotal(data.costOfSales), false, false, true)}

                {renderRow('Gross profit', data.grossProfit, false, false, true)}

                {renderRow('Operating expenses', 0, false, true)}
                {(data.operatingExpenses || []).map(expense => renderRow(expense.category, -expense.amount, true))}
                {renderRow('Total operating expenses', -calculateTotal(data.operatingExpenses), false, false, true)}

                {renderRow('Operating profit', data.operatingProfit, false, false, true)}

                {renderRow('Administrative expense', 0, false, true)}
                {(data.administrativeExpenses || []).map(expense => renderRow(expense.category, -expense.amount, true))}
                {renderRow('Total administrative expense', -calculateTotal(data.administrativeExpenses), false, false, true)}

                {renderRow('EBITDA', data.ebitda, false, false, true)}

                {renderRow('Interest expense', -data.interestExpenses, true)}
                {renderRow('Taxes', -data.taxes, true)}
                {renderRow('Depreciation', -data.depreciation, true)}
                {renderRow('Net profit', data.netProfit, false, false, true)}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </LocalizationProvider>
  );
};

export default PLPage;

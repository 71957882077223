import React, { useState } from 'react';
import { 
  Box, 
  TextField, 
  IconButton, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Button,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../api';
import { useAuth } from '../contexts/AuthContext';

const CompartmentCrud = ({ compartments, fetchCompartments }) => {
  const { clinic } = useAuth(); // Get the clinic ID from the authentication context
  const [newCompartmentName, setNewCompartmentName] = useState(''); // State for new compartment name
  const [loading, setLoading] = useState(false); // Loading state

  // Handle the creation of a new compartment
  const handleCreateCompartment = async () => {
    if (!newCompartmentName) return; // Do nothing if the name is empty
    setLoading(true);
    try {
      await api.post('/compartments', {
        name: newCompartmentName,
        clinic: clinic._id,
      });
      setNewCompartmentName(''); // Reset the input field
      fetchCompartments(); // Refresh compartments list
    } catch (error) {
      console.error('Error creating compartment:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle the deletion of a compartment
  const handleDeleteCompartment = async (compartmentId) => {
    setLoading(true);
    try {
      await api.delete(`/compartments/${compartmentId}`);
      fetchCompartments(); // Refresh compartments list
    } catch (error) {
      console.error('Error deleting compartment:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h2" gutterBottom>Compartments</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <TextField
          placeholder="Add new..."
          value={newCompartmentName}
          onChange={(e) => setNewCompartmentName(e.target.value)}
          variant="outlined"
          size="small"
          fullWidth
          sx={{ mr: 1 }}
        />
        <Button
          variant="contained"
          onClick={handleCreateCompartment}
          disabled={loading}
        >
          Add
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {compartments.map((compartment) => (
              <TableRow key={compartment._id}>
                <TableCell>{compartment.name}</TableCell>
                <TableCell align="right">
                  <IconButton 
                    onClick={() => handleDeleteCompartment(compartment._id)}
                    disabled={loading}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CompartmentCrud;

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function GoogleAuthCallback() {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleGoogleAuthSuccess, handleGoogleAuthError } = useAuth();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const googleAuth = params.get('googleAuth');

    if (googleAuth === 'success') {
      handleGoogleAuthSuccess();
      navigate('/appointments', { state: { googleAuthSuccess: true } });
    } else {
      handleGoogleAuthError();
      navigate('/appointments', { state: { googleAuthError: true } });
    }
  }, [location, navigate, handleGoogleAuthSuccess, handleGoogleAuthError]);

  return <div>Processing Google authentication...</div>;
}

export default GoogleAuthCallback;

import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, MenuItem, FormControl, Select
} from '@mui/material';
import dayjs from 'dayjs';
import api from '../api';

const BalanceSheetPage = () => {
  const currentYear = dayjs().year();

  const [year, setYear] = useState(currentYear);
  const [data, setData] = useState({
    assets: {},
    liabilities: {},
    equity: {},
    totalAssets: 0,
    totalLiabilities: 0,
    totalEquity: 0,
    totalLiabilitiesAndEquity: 0
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.post('/financialTransactions/balanceSheet', {
          year: year
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching balance sheet data:', error);
      }
    };

    fetchData();
  }, [year]);

  const formatAmount = (amount) => {
    if (isNaN(amount) || amount === null || amount === undefined) {
      return '$0';
    }
    const formatted = amount.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return amount < 0 ? `($${formatted})` : `$${formatted}`;
  };

  const renderRow = (category, amount, isIndented = false, isHeader = false, isSubtotal = false) => (
    <TableRow key={category} sx={{ backgroundColor: isSubtotal ? '#f5f5f5' : 'transparent', fontWeight: isSubtotal ? 'bold' : 'normal' }}>
      <TableCell sx={{ paddingLeft: isIndented ? 4 : isHeader || isSubtotal ? 2 : 0, fontWeight: isHeader || isSubtotal ? 'bold' : 'normal' }}>{category}</TableCell>
      <TableCell sx={{ fontWeight: isSubtotal ? 'bold' : 'normal' }}>{isHeader ? '' : formatAmount(amount)}</TableCell>
    </TableRow>
  );

  return (
    <Container>
      <Typography variant="h2">Balance Sheet</Typography>
      <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 2 }}>
        <FormControl>
          <Select
            value={year}
            onChange={(e) => setYear(e.target.value)}
            displayEmpty
          >
            {[...Array(10).keys()].map(offset => (
              <MenuItem key={currentYear - offset} value={currentYear - offset}>
                {currentYear - offset}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Account</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderRow('Assets', 0, false, true)}
              {Object.keys(data.assets).map(asset => renderRow(asset, data.assets[asset], true))}
              {renderRow('Total Assets', data.totalAssets, false, false, true)}

              {renderRow('Liabilities', 0, false, true)}
              {Object.keys(data.liabilities).map(liability => renderRow(liability, data.liabilities[liability], true))}
              {renderRow('Total Liabilities', data.totalLiabilities, false, false, true)}

              {renderRow('Equity', 0, false, true)}
              {Object.keys(data.equity).map(equityItem => renderRow(equityItem, data.equity[equityItem], true))}
              {renderRow('Total Equity', data.totalEquity, false, false, true)}

              {renderRow('Total Liabilities and Equity', data.totalLiabilitiesAndEquity, false, false, true)}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default BalanceSheetPage;

import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Button } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import api from '../api'; // Use the configured Axios instance
import ScheduleModal from '../components/ScheduleModal';
import { useAuth } from '../contexts/AuthContext'; // Import the authentication context

const SchedulesPage = () => {
  // State to hold the list of schedules
  const [schedules, setSchedules] = useState([]);
  
  // State to control the visibility of the ScheduleModal
  const [isScheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  
  // State to control whether the modal is in edit mode
  const [editMode, setEditMode] = useState(false);
  
  // State to hold the current schedule being edited
  const [currentSchedule, setCurrentSchedule] = useState(null);
  
  // Extract the clinic object from the authentication context
  const { clinic } = useAuth();

  // useEffect hook to fetch schedules when the clinic changes
  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        const response = await api.get(`/schedules?clinicId=${clinic._id}`);
        setSchedules(response.data);
      } catch (error) {
        console.error('Error fetching schedules:', error);
      }
    };

    if (clinic) {
      fetchSchedules();
    }
  }, [clinic]);

  // Handler to open the modal for adding a new schedule
  const handleAddScheduleClick = () => {
    setEditMode(false);
    setCurrentSchedule(null);
    setScheduleDialogOpen(true);
  };

  // Handler to update the list of schedules after adding or editing a schedule
  const handleScheduleAddedOrUpdated = (newOrUpdatedSchedule) => {
    if (editMode) {
      setSchedules(schedules.map((schedule) => (schedule._id === newOrUpdatedSchedule._id ? newOrUpdatedSchedule : schedule)));
    } else {
      setSchedules([...schedules, newOrUpdatedSchedule]);
    }
  };

  // Handler to delete a schedule
  const handleDeleteSchedule = async (id) => {
    try {
      await api.delete(`/schedules/${id}?clinicId=${clinic._id}`);
      setSchedules(schedules.filter((schedule) => schedule._id !== id));
    } catch (error) {
      console.error('Error deleting schedule:', error);
    }
  };

  return (
    <Container>
      {/* Header section with title and add schedule button */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h2">Horarios</Typography>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddScheduleClick}>
          Agregar Horario
        </Button>
      </Box>
      
      {/* Table displaying the list of schedules */}
      <Table sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Día</TableCell>
            <TableCell>Hora de Inicio</TableCell>
            <TableCell>Hora de Fin</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedules.map((schedule) => (
            <TableRow
              key={schedule._id}
              sx={{
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                  cursor: 'pointer',
                },
              }}
            >
              <TableCell>{schedule.day}</TableCell>
              <TableCell>{schedule.startTime}</TableCell>
              <TableCell>{schedule.endTime}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleDeleteSchedule(schedule._id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
      {/* ScheduleModal component to handle adding and editing schedules */}
      <ScheduleModal
        open={isScheduleDialogOpen}
        onClose={() => setScheduleDialogOpen(false)}
        onScheduleAddedOrUpdated={handleScheduleAddedOrUpdated}
        editMode={editMode}
        schedule={currentSchedule}
      />
    </Container>
  );
};

export default SchedulesPage;

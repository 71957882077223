import React, { useState, useEffect } from 'react';
import {
  Drawer,
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Slider,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../contexts/AuthContext';
import api from '../api';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const AddLeadDrawer = ({ open, onClose, onLeadAdded, onLeadUpdated, editingLead }) => {
  const { clinic } = useAuth();
  const [leadData, setLeadData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    status: 'new',
    interestScore: 0,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (editingLead) {
      setLeadData(editingLead);
    } else {
      setLeadData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        status: 'new',
        interestScore: 0,
      });
    }
  }, [editingLead]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeadData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handlePhoneChange = (value) => {
    setLeadData(prevData => ({
      ...prevData,
      phone: value
    }));
  };

  const handleInterestScoreChange = (event, newValue) => {
    setLeadData(prevData => ({
      ...prevData,
      interestScore: newValue
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!leadData.firstName.trim()) {
      alert('First name is required');
      return;
    }
    setIsSubmitting(true);
    try {
      if (editingLead) {
        const response = await api.patch(`/leads/${editingLead._id}`, leadData);
        onLeadUpdated(response.data);
      } else {
        const response = await api.post('/leads', { ...leadData, clinic: clinic._id });
        onLeadAdded(response.data);
      }
      onClose();
    } catch (error) {
      console.error('Error saving lead:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Box sx={{ width: 300, p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">{editingLead ? 'Edit Lead' : 'Add New Lead'}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            value={leadData.firstName}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            value={leadData.lastName}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={leadData.email}
            onChange={handleChange}
            margin="normal"
          />
          <Box sx={{ my: 2 }}>
            <PhoneInput
              country={'co'}
              value={leadData.phone}
              onChange={handlePhoneChange}
              inputProps={{
                name: 'phone',
                required: true,
              }}
            />
          </Box>
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={leadData.status}
              onChange={handleChange}
            >
              <MenuItem value="new">New</MenuItem>
              <MenuItem value="contacted">Contacted</MenuItem>
              <MenuItem value="interested">Interested</MenuItem>
              <MenuItem value="converted">Converted</MenuItem>
              <MenuItem value="lost">Lost</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ mt: 2 }}>
            <Typography gutterBottom>Interest Score</Typography>
            <Slider
              value={leadData.interestScore}
              onChange={handleInterestScoreChange}
              aria-labelledby="interest-score-slider"
              valueLabelDisplay="auto"
              step={0.1}
              marks
              min={0}
              max={1}
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : (editingLead ? 'Update Lead' : 'Add Lead')}
          </Button>
        </form>
      </Box>
    </Drawer>
  );
};

export default AddLeadDrawer;

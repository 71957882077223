import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import FileUploadForm from './FileUploadForm';

const FileUploadModal = ({ open, onClose, patientId, onUploadSuccess, onUploadError }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Upload Files</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Please select a file to upload. Only PDF, JPEG, and PNG files are allowed. The maximum file size is 20MB.
        </Typography>
        <FileUploadForm 
          patientId={patientId} 
          onUploadSuccess={onUploadSuccess}
          onUploadError={onUploadError}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadModal;

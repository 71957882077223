import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText, CircularProgress, Alert } from '@mui/material';
import api from '../api';

const LeadConversationHistory = ({ leadId }) => {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/whatsapp/messages/lead/${leadId}`);
        setConversations(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching lead conversations:', error);
        setError('Failed to load conversations. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchConversations();
  }, [leadId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Conversation History
        </Typography>
        {conversations.length === 0 ? (
          <Typography>No conversations recorded.</Typography>
        ) : (
          <List>
            {conversations.map((message) => (
              <ListItem key={message._id} divider>
                <ListItemText
                  primary={new Date(message.sentAt).toLocaleString()}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="textPrimary">
                        {message.status === 'received' ? 'Lead: ' : 'AI: '}
                      </Typography>
                      {message.messageBody}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default LeadConversationHistory;

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', // Negro para elementos principales (botones, iconos, etc.)
    },
    secondary: {
      main: '#FFFFFF', // Blanco para el texto o elementos secundarios sobre fondo oscuro
    },
    background: {
      default: '#F0F0F0', // Un gris muy claro para el fondo general
      paper: '#FFFFFF',   // Fondo blanco para las tarjetas y componentes
    },
    text: {
      primary: '#000000', // Texto negro para el texto principal
      secondary: '#6B7280', // Un gris intermedio para texto secundario
    },
    success: {
      main: '#A5D6A7', // Verde pastel suave para éxito
      contrastText: '#1F1F1F', // Texto oscuro sobre el fondo verde
    },
    warning: {
      main: '#FFCC80', // Naranja claro para advertencias
      contrastText: '#1F1F1F', // Texto oscuro
    },
    error: {
      main: '#FFAB91', // Rojo suave para errores o pendientes
      contrastText: '#1F1F1F', // Texto oscuro
    },
    info: {
      main: '#90CAF9', // Azul claro para información
      contrastText: '#1F1F1F', // Texto oscuro
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '2.2rem', // Encabezado principal
      fontWeight: 700,    // Encabezados en negrita
      color: '#000000',   // Color negro
    },
    h2: {
      fontSize: '1.5rem', // Encabezado secundario
      fontWeight: 600,    // Negrita para subencabezados
      color: '#000000',   // Color negro
    },
    body1: {
      fontSize: '1rem', // Texto estándar
      color: '#000000', // Color de texto negro para máxima legibilidad
    },
    button: {
      textTransform: 'none', // Sin mayúsculas automáticas en botones
      fontWeight: 'bold',    // Botones en negrita
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px', // Botones menos redondeados para un look más minimalista
          backgroundColor: '#000000', // Fondo negro para botones
          color: '#FFFFFF', // Texto blanco en los botones
          '&:hover': {
            backgroundColor: '#333333', // Un gris oscuro para hover
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '20px',
          borderRadius: '12px', // Bordes ligeramente redondeados para las tarjetas
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)', // Sombras suaves
          backgroundColor: '#FFFFFF', // Fondo blanco
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '16px',
          borderRadius: '12px', // Bordes redondeados
          backgroundColor: '#FFFFFF', // Fondo blanco
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '16px', // Bordes ligeramente redondeados para los chips
          padding: '2px 4px',
        },
        colorSuccess: {
          backgroundColor: 'rgba(165, 214, 167, 0.3)', // Verde pastel suave con 80% de opacidad
          color: '#1F1F1F', // Texto oscuro
        },
        colorError: {
          backgroundColor: 'rgba(255, 171, 145, 0.3)', // Rojo suave con 80% de opacidad
          color: '#1F1F1F', // Texto oscuro
        },
        colorWarning: {
          backgroundColor: 'rgba(255, 204, 128, 0.3)', // Naranja claro con 80% de opacidad
          color: '#1F1F1F', // Texto oscuro
        },
        colorInfo: {
          backgroundColor: 'rgba(144, 202, 249, 0.3)', // Azul claro con 80% de opacidad
          color: '#1F1F1F', // Texto oscuro
        },
        
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.875rem', // Tamaño más pequeño para el texto principal
        },
        secondary: {
          fontSize: '0.75rem', // Tamaño más pequeño para el texto secundario, si se usa
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '32px', // Ajustar el tamaño mínimo del icono
          fontSize: '1.25rem', // Tamaño del icono (puedes ajustarlo según prefieras)
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.85rem', // 10% más pequeño que el tamaño base (asumiendo que 1rem es el tamaño base)
        },
        head: {
          fontWeight: 'bold', // Encabezados en negrita
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px', // Esquinas redondeadas
            fontSize: '0.85rem', // 15% más pequeño que el tamaño base
          },
          '& .MuiInputLabel-root': {
            fontSize: '0.85rem', // 15% más pequeño que el tamaño base
          },
          '& .MuiInputBase-input': {
            fontSize: '0.85rem', // 15% más pequeño que el tamaño base
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px', // Esquinas redondeadas
          },
          '& .MuiInputBase-input': {
            fontSize: '0.85rem', // 15% más pequeño que el tamaño base
          },
          '& .MuiAutocomplete-inputRoot': {
            fontSize: '0.85rem', // 15% más pequeño que el tamaño base
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        .react-tel-input {
          width: 100%;
        }
        .react-tel-input .form-control {
          width: 100%;
          height: 56px;
          font-size: 0.85rem;
          padding: 16.5px 14px 16.5px 58px;
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.23);
        }
        .react-tel-input .form-control:hover {
          border: 1px solid rgba(0, 0, 0, 0.87);
        }
        .react-tel-input .form-control:focus {
          border: 2px solid #1976d2;
          box-shadow: none;
        }
        .react-tel-input .flag-dropdown {
          border: none;
          background: none;
          border-radius: 8px 0 0 8px;
        }
        .react-tel-input .selected-flag {
          background-color: transparent;
          width: 58px;
        }
        .react-tel-input .selected-flag:hover,
        .react-tel-input .selected-flag:focus {
          background-color: rgba(0, 0, 0, 0.05);
        }
        .react-tel-input .country-list {
          border-radius: 8px;
          box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
        }
        .react-tel-input .special-label {
          display: none;
        }
      `,
    },
  },
});

export default theme;
import React, { useEffect, useState } from 'react';
import { 
  Box, 
  TextField, 
  Autocomplete, 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Typography,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../api';
import { useAuth } from '../contexts/AuthContext';

const EnvironmentalConditionDataGrid = ({ compartments }) => {
  const { clinic } = useAuth();
  const [conditions, setConditions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newCondition, setNewCondition] = useState({
    temperature: '',
    humidity: '',
    compartment: null,
  });

  const fetchConditions = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/environmental-conditions/clinic/${clinic._id}`);
      const sortedConditions = response.data.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setConditions(sortedConditions);
    } catch (error) {
      console.error('Error fetching conditions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clinic) {
      fetchConditions();
    }
  }, [clinic]);

  const handleAddCondition = async () => {
    if (!newCondition.compartment || !newCondition.temperature || !newCondition.humidity) {
      return;
    }

    try {
      await api.post('/environmental-conditions', {
        temperature: newCondition.temperature,
        humidity: newCondition.humidity,
        compartment: newCondition.compartment._id,
        clinic: clinic._id,
      });
      fetchConditions();
      setNewCondition({ temperature: '', humidity: '', compartment: null });
    } catch (error) {
      console.error('Error adding condition:', error);
    }
  };

  const handleDeleteCondition = async (conditionId) => {
    try {
      await api.delete(`/environmental-conditions/${conditionId}`);
      fetchConditions();
    } catch (error) {
      console.error('Error deleting condition:', error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        Temperature and Humidity Log
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Autocomplete
          options={compartments}
          getOptionLabel={(option) => option.name}
          value={newCondition.compartment}
          onChange={(e, value) => setNewCondition({ ...newCondition, compartment: value })}
          renderInput={(params) => <TextField {...params} label="Compartment" size="small" />}
          sx={{ flexGrow: 1, mr: 1 }}
        />
        <TextField
          label="Temperature (°C)"
          type="number"
          value={newCondition.temperature}
          onChange={(e) => setNewCondition({ ...newCondition, temperature: e.target.value })}
          size="small"
          sx={{ mr: 1 }}
        />
        <TextField
          label="Humidity (%)"
          type="number"
          value={newCondition.humidity}
          onChange={(e) => setNewCondition({ ...newCondition, humidity: e.target.value })}
          size="small"
          sx={{ mr: 1 }}
        />
        <Button
          variant="contained"
          onClick={handleAddCondition}
          disabled={loading}
        >
          Add
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Temperature (°C)</TableCell>
              <TableCell>Humidity (%)</TableCell>
              <TableCell>Compartment</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {conditions.map((condition) => (
              <TableRow key={condition._id}>
                <TableCell>{formatDate(condition.timestamp)}</TableCell>
                <TableCell>{condition.temperature}</TableCell>
                <TableCell>{condition.humidity}</TableCell>
                <TableCell>
                  {condition.compartment && condition.compartment.name
                    ? condition.compartment.name
                    : 'N/A'}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => handleDeleteCondition(condition._id)}
                    disabled={loading}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EnvironmentalConditionDataGrid;

import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import CompartmentCrud from '../components/CompartmentCrud';
import EnvironmentalConditionDataGrid from '../components/EnvironmentalConditionDataGrid';
import api from '../api';
import { useAuth } from '../contexts/AuthContext';

const TemperatureHumidityControlPage = () => {
  const { clinic } = useAuth();
  const [compartments, setCompartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCompartments = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/compartments/clinic/${clinic._id}`);
      setCompartments(response.data);
    } catch (error) {
      console.error('Error fetching compartments:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clinic) {
      fetchCompartments();
    }
  }, [clinic]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={6}>
        {/* Columna izquierda: CRUD de compartimentos */}
        <Grid item xs={12} md={4}>
          <Box>
            <CompartmentCrud compartments={compartments} fetchCompartments={fetchCompartments} />
          </Box>
        </Grid>

        {/* Columna derecha: Logs de temperatura y humedad */}
        <Grid item xs={12} md={8}>
          <Box>
            <EnvironmentalConditionDataGrid compartments={compartments} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TemperatureHumidityControlPage;

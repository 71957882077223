import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Paper, 
  Grid, 
  Snackbar, 
  Alert,
  Autocomplete
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useAuth } from '../contexts/AuthContext';
import api from '../api';
import countries from '../utils/countries'; // Asegúrate de tener este archivo con la lista de países
import timezones from '../utils/timezones'; // Asegúrate de tener este archivo con la lista de zonas horarias

function ClinicSettings() {
  const { clinic, updateClinic } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    city: '',
    country: '',
    phone: '',
    timezone: '',
    instagramLink: '',
    googleMapsLink: '',
    websiteLink: ''
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    if (clinic) {
      setFormData({
        name: clinic.name || '',
        address: clinic.address || '',
        city: clinic.city || '',
        country: clinic.country || '',
        phone: clinic.phone || '',
        timezone: clinic.timezone || '',
        instagramLink: clinic.instagramLink || '',
        googleMapsLink: clinic.googleMapsLink || '',
        websiteLink: clinic.websiteLink || ''
      });
    }
  }, [clinic]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.put(`/clinics/update-clinic-config/${clinic._id}`, formData);
      console.log('Server response:', response.data);
      if (response.data && response.data.clinic) {
        updateClinic(response.data.clinic);
        setSnackbar({
          open: true,
          message: 'Clinic settings updated successfully',
          severity: 'success'
        });
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error updating clinic settings:', error);
      setSnackbar({
        open: true,
        message: 'Error updating clinic settings: ' + (error.response?.data?.message || error.message),
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Clinic Settings
      </Typography>
      <Paper sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Clinic Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={countries}
                value={formData.country}
                onChange={(event, newValue) => {
                  setFormData({ ...formData, country: newValue });
                }}
                renderInput={(params) => <TextField {...params} label="Country" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneInput
                country={'co'} // país por defecto
                value={formData.phone}
                onChange={handlePhoneChange}
                inputStyle={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={timezones}
                value={formData.timezone}
                onChange={(event, newValue) => {
                  setFormData({ ...formData, timezone: newValue.value }); // Usa solo el valor, no todo el objeto
                }}
                renderInput={(params) => <TextField {...params} label="Timezone" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Instagram Link"
                name="instagramLink"
                value={formData.instagramLink}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Google Maps Link"
                name="googleMapsLink"
                value={formData.googleMapsLink}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Website Link"
                name="websiteLink"
                value={formData.websiteLink}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ClinicSettings;

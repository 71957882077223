import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText, CircularProgress, Alert, Chip, Link, IconButton, Menu, MenuItem } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import api from '../api';
import dayjs from 'dayjs';

const LeadAppointmentsList = ({ leadId }) => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const formatDate = (dateString) => {
    return dayjs(dateString).format('DD-MMM-YYYY. h:mma').replace('.', '').toLowerCase();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'completed':
        return 'success';
      case 'canceled':
        return 'error';
      default:
        return 'default';
    }
  };

  const handleMenuOpen = (event, appointment) => {
    setAnchorEl(event.currentTarget);
    setSelectedAppointment(appointment);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedAppointment(null);
  };

  const handleStatusChange = async (newStatus) => {
    if (!selectedAppointment) return;

    try {
      await api.patch(`/appointments/${selectedAppointment._id}`, { status: newStatus });
      setAppointments(appointments.map(app => 
        app._id === selectedAppointment._id ? { ...app, status: newStatus } : app
      ));
    } catch (error) {
      console.error('Error updating appointment status:', error);
    }
    handleMenuClose();
  };

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/appointments/person/${leadId}`);
        setAppointments(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching lead appointments:', error);
        setError('Failed to load appointments. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, [leadId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Scheduled Appointments
        </Typography>
        {appointments.length === 0 ? (
          <Typography>No scheduled appointments.</Typography>
        ) : (
          <List>
            {appointments.map((appointment, index) => (
              <ListItem 
                key={appointment._id} 
                divider={index < appointments.length - 1}
                secondaryAction={
                  <IconButton edge="end" onClick={(event) => handleMenuOpen(event, appointment)}>
                    <MoreVertIcon />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={
                    <Link
                      component={RouterLink}
                      to={`/appointments?eventId=${appointment._id}`}
                      color="primary"
                    >
                      {formatDate(appointment.startDate)}
                    </Link>
                  }
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="textPrimary">
                        Duration: {appointment.appointmentLength} minutes
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2">
                        Description: {appointment.description || 'No description'}
                      </Typography>
                      <br />
                      <Chip
                        label={appointment.status}
                        color={getStatusColor(appointment.status)}
                        size="small"
                        style={{ marginTop: '4px' }}
                      />
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleStatusChange('pending')}>Set as Pending</MenuItem>
        <MenuItem onClick={() => handleStatusChange('completed')}>Set as Completed</MenuItem>
        <MenuItem onClick={() => handleStatusChange('canceled')}>Set as Canceled</MenuItem>
      </Menu>
    </Card>
  );
};

export default LeadAppointmentsList;

import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, Tabs, Tab, Snackbar, Alert, MenuItem, Autocomplete, InputAdornment
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import api from '../api';

const LongTermLoanDialog = ({ open, onClose, onLoanAdded, onLoanPaid }) => {
  const [lender, setLender] = useState('');
  const [amount, setAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [term, setTerm] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [account, setAccount] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [loans, setLoans] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [tabIndex, setTabIndex] = useState(0);
  const [loanId, setLoanId] = useState('');
  const [paymentAmount, setPaymentAmount] = useState('');
  const [interestAmount, setInterestAmount] = useState('');
  const [paymentDate, setPaymentDate] = useState(dayjs());

  useEffect(() => {
    if (open) {
      fetchAccounts();
      fetchLoans();
    }
  }, [open]);

  const fetchAccounts = async () => {
    try {
      const response = await api.get('/accounts');
      const filteredAccounts = response.data.filter(acc => acc.code === '1101' || acc.code === '1102');
      setAccounts(filteredAccounts);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const fetchLoans = async () => {
    try {
      const response = await api.get('/longtermloans/pending');
      setLoans(response.data);
    } catch (error) {
      console.error('Error fetching loans:', error);
    }
  };

  useEffect(() => {
    if (startDate && term) {
      const calculatedEndDate = dayjs(startDate).add(parseInt(term, 10), 'year');
      setEndDate(calculatedEndDate);
    }
  }, [startDate, term]);

  const handleAddLoan = async () => {
    try {
      const newLoan = {
        lender,
        amount: parseFloat(amount.replace(/,/g, '').replace('$', '')),
        interestRate: parseFloat(interestRate.replace(',', '.')),
        term: parseInt(term, 10),
        startDate,
        endDate,
        account
      };
      const response = await api.post('/longtermloans', newLoan);
      onLoanAdded(response.data);
      setSnackbarMessage('Long-term loan added successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleClose();
    } catch (error) {
      setSnackbarMessage('Error adding long-term loan');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handlePayLoan = async () => {
    try {
      const paymentData = {
        loanId,
        paymentAmount: parseFloat(paymentAmount.replace(/,/g, '').replace('$', '')),
        interestAmount: parseFloat(interestAmount.replace(/,/g, '').replace('$', '')),
        paymentDate,
        account
      };
      const response = await api.post('/longtermloans/payoff', paymentData);
      onLoanPaid(response.data);
      setSnackbarMessage('Loan payment recorded successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleClose();
    } catch (error) {
      setSnackbarMessage('Error recording loan payment');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleClose = () => {
    onClose();
    // Reset form fields
    setLender('');
    setAmount('');
    setInterestRate('');
    setTerm('');
    setStartDate(null);
    setEndDate(null);
    setAccount('');
    setLoanId('');
    setPaymentAmount('');
    setInterestAmount('');
    setPaymentDate(dayjs());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Long-term Loan</DialogTitle>
        <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label="Add Loan" />
          <Tab label="Pay Loan" />
        </Tabs>
        <DialogContent>
          {tabIndex === 0 && (
            <Box>
              <TextField
                margin="dense"
                label="Lender"
                fullWidth
                variant="outlined"
                value={lender}
                onChange={(e) => setLender(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Amount"
                fullWidth
                variant="outlined"
                value={amount}
                onChange={(e) => setAmount(new Intl.NumberFormat().format(e.target.value.replace(/,/g, '').replace('$', '')))}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              <TextField
                margin="dense"
                label="Interest Rate (%) - Effective Annual Rate"
                fullWidth
                variant="outlined"
                value={interestRate}
                onChange={(e) => setInterestRate(e.target.value.replace(',', '.'))}
              />
              <TextField
                margin="dense"
                label="Term (years)"
                fullWidth
                variant="outlined"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
              />
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="dense" variant="outlined" />}
              />
              <TextField
                select
                label="Account"
                fullWidth
                margin="dense"
                variant="outlined"
                value={account}
                onChange={(e) => setAccount(e.target.value)}
              >
                {accounts.map((acc) => (
                  <MenuItem key={acc._id} value={acc._id}>
                    {acc.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )}
          {tabIndex === 1 && (
            <Box>
              <Autocomplete
                options={loans}
                getOptionLabel={(option) => {
                  const capitalPaidToDate = option.capitalPaidToDate || 0;
                  return `${option.lender} - $${option.amount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} (Capital paid to date $${capitalPaidToDate.toLocaleString()})`;
                }}
                value={loans.find(loan => loan._id === loanId) || null}
                onChange={(event, newValue) => setLoanId(newValue?._id || '')}
                renderInput={(params) => <TextField {...params} label="Loan ID" margin="dense" variant="outlined" fullWidth />}
              />
              <TextField
                margin="dense"
                label="Payment Amount"
                fullWidth
                variant="outlined"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(new Intl.NumberFormat().format(e.target.value.replace(/,/g, '').replace('$', '')))}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              <TextField
                margin="dense"
                label="Interest Payment Amount"
                fullWidth
                variant="outlined"
                value={interestAmount}
                onChange={(e) => setInterestAmount(new Intl.NumberFormat().format(e.target.value.replace(/,/g, '').replace('$', '')))}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              <DatePicker
                label="Payment Date"
                value={paymentDate}
                onChange={(newValue) => setPaymentDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="dense" variant="outlined" />}
              />
              <TextField
                select
                label="Account"
                fullWidth
                margin="dense"
                variant="outlined"
                value={account}
                onChange={(e) => setAccount(e.target.value)}
              >
                {accounts.map((acc) => (
                  <MenuItem key={acc._id} value={acc._id}>
                    {acc.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          {tabIndex === 0 ? (
            <Button onClick={handleAddLoan} color="primary">
              Save
            </Button>
          ) : (
            <Button onClick={handlePayLoan} color="primary">
              Save
            </Button>
          )}
        </DialogActions>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Dialog>
    </LocalizationProvider>
  );
};

export default LongTermLoanDialog;

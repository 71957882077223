import React, { useState, useEffect } from 'react';
import api from '../api';
import { Container, Typography, Box, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton, CircularProgress, TableContainer, Paper, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddProductModal from '../components/AddProductModal';
import EditProductModal from '../components/EditProductModal';
import { useAuth } from '../contexts/AuthContext';

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddProductModalOpen, setAddProductModalOpen] = useState(false);
  const [isEditProductModalOpen, setEditProductModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { clinic } = useAuth();

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(`/products`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (clinic) {
      fetchProducts();
    }
  }, [clinic]);

  const handleAddProductClick = () => {
    setAddProductModalOpen(true);
  };

  const handleEditProductClick = (product) => {
    setSelectedProduct(product);
    setEditProductModalOpen(true);
  };

  const handleModalClose = () => {
    setAddProductModalOpen(false);
    setEditProductModalOpen(false);
    setSelectedProduct(null);
  };

  const handleProductAdded = async () => {
    try {
      const response = await api.get(`/products`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    handleModalClose();
  };

  const handleDeleteProduct = async (id) => {
    try {
      await api.delete(`/products/${id}`);
      setProducts(products.filter((product) => product._id !== id));
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  if (isLoading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        {/* Page title */}
        <Grid item xs={12}>
          <Typography variant="h2">Products</Typography>
        </Grid>

        {/* Add Product button */}
        <Grid item xs={12} sm={2}>
          <Button variant="contained" color="primary" onClick={handleAddProductClick} fullWidth>
            Add Product
          </Button>
        </Grid>

        {/* Products table */}
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Purchase Unit</TableCell>
                  <TableCell>Usage Unit</TableCell>
                  <TableCell>Current Stock</TableCell>
                  <TableCell>Minimum Usage Stock</TableCell>
                  <TableCell>Sanitary Registry</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow key={product._id} hover>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.type}</TableCell>
                    <TableCell>{`${product.purchaseUnit?.quantity || ''} ${product.purchaseUnit?.unitMeasure || ''}`}</TableCell>
                    <TableCell>{`${product.usageUnit?.quantity || ''} ${product.usageUnit?.unitMeasure || ''}`}</TableCell>
                    <TableCell>{product.currentStock}</TableCell>
                    <TableCell>{product.minimumUsageStock}</TableCell>
                    <TableCell>{product.sanitaryRegistry}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleEditProductClick(product)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteProduct(product._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <AddProductModal
        open={isAddProductModalOpen}
        onClose={handleModalClose}
        onProductAdded={handleProductAdded}
      />
      {selectedProduct && (
        <EditProductModal
          open={isEditProductModalOpen}
          onClose={handleModalClose}
          onProductUpdated={handleProductAdded}
          product={selectedProduct}
        />
      )}
    </Container>
  );
};

export default ProductsPage;
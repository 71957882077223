import React, { useState, useCallback } from 'react';
import { Container, Typography, Tabs, Tab, Box } from '@mui/material';
import ProvidersTable from '../components/ProvidersTable';
import PurchaseOrdersTable from '../components/PurchaseOrdersTable';
import InventoryRecordsTable from '../components/InventoryRecordsTable';

function TabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function ProvidersAndInventoryPage() {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" sx={{ mb: 4 }}>
        Providers & Inventory
      </Typography>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="providers and inventory tabs">
        <Tab label="Purchase Orders" />
        <Tab label="Inventory Records" />
        <Tab label="Providers" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <PurchaseOrdersTable />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <InventoryRecordsTable />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ProvidersTable />
      </TabPanel>
    </Container>
  );
}

export default ProvidersAndInventoryPage;
import React, { useState } from 'react';
import api from '../api';
import { 
  Stepper, Step, StepLabel, Button, Typography, Box, CircularProgress, 
  Snackbar, Autocomplete, TextField, List, ListItem, ListItemText,
  Card, CardContent, Dialog, DialogTitle, DialogContent, DialogActions,
  Alert, AlertTitle, Grid
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { CloudUpload, GetApp, Check } from '@mui/icons-material';

const steps = ['Select Schema and Upload File', 'Map and Validate Data', 'Confirm Import'];

function ImportDataPage() {
  const [activeStep, setActiveStep] = useState(0);
  const [schema, setSchema] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataPreview, setDataPreview] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [uploadedFilename, setUploadedFilename] = useState('');
  const [duplicates, setDuplicates] = useState([]); // Estado para almacenar registros duplicados

  const schemas = [
    'Patients', 'Providers', 'Products', 
    'Appointments', 'ClinicalHistories', 
    'InformedConsents', 'Users'
  ];

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleSchemaChange = (event, newValue) => {
    setSchema(newValue);
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    if (selectedFile && schema) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('schema', schema);

      try {
        setLoading(true);
        const uploadResponse = await api.post('/import/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (uploadResponse.status === 200) {
          const { filename } = uploadResponse.data;
          setUploadedFilename(filename);

          const previewResponse = await api.post('/import/process', {
            filename,
            schema,
          });

          if (previewResponse.status === 200) {
            setDataPreview(previewResponse.data.preview);
            setActiveStep(1);
          } else {
            setSnackbarMessage('Failed to process file.');
            setSnackbarOpen(true);
          }
        } else {
          setSnackbarMessage('Failed to upload file.');
          setSnackbarOpen(true);
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.details) {
          const errorDetails = error.response.data.details.map(detail => `Row ${detail.row}: Missing fields - ${detail.missingFields.join(', ')}`).join('\n');
          setSnackbarMessage(`Errors found during import:\n${errorDetails}`);
        } else {
          setSnackbarMessage('Failed to upload file.');
        }
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleConfirmImport = async () => {
    try {
      setLoading(true);
      const response = await api.post('/import/confirm', {
        filename: uploadedFilename,
        schema,
      });

      if (response.status === 200) {
        const { duplicates } = response.data;
        setDuplicates(duplicates); // Almacenar duplicados en el estado
        setActiveStep(2);
        setSnackbarMessage('Data imported successfully!');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Failed to confirm import.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Failed to confirm import.');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const downloadTemplate = async () => {
    try {
      const response = await api.get(`/import/template/${schema}`, {
        responseType: 'blob',
      });

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `${schema}-template.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        setSnackbarMessage('Failed to download template.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error downloading template:', error);
      setSnackbarMessage('Failed to download template.');
      setSnackbarOpen(true);
    }
  };

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          {activeStep === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  Data Import from Other Systems
                </Typography>
                <Typography variant="body1" gutterBottom>
                  To begin the import process, select the data schema you want to upload and then upload a CSV file with the data. Make sure the file is correctly formatted before proceeding.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={schemas}
                  value={schema}
                  onChange={handleSchemaChange}
                  renderInput={(params) => <TextField {...params} label="Select Schema" fullWidth />}
                />
              </Grid>
              <Grid item xs={12}>
                {schema && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2">
                      Download the CSV template for this schema and use it to correctly format your data. 
                      Make sure to follow the instructions to avoid errors during import.
                    </Typography>
                    <Button 
                      onClick={downloadTemplate}
                      startIcon={<GetApp />}
                      variant="outlined"
                      sx={{ mt: 1 }}
                    >
                      {`Download CSV Template for ${schema}`}
                    </Button>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  disabled={!schema || loading}
                  startIcon={<CloudUpload />}
                >
                  Upload CSV File
                  <input
                    type="file"
                    accept=".csv"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>
            </Grid>
          )}
          {activeStep === 1 && (
            <>
              {dataPreview.length > 0 ? (
                <Box sx={{ height: 400, width: '100%' }}>
                  <DataGrid
                    rows={dataPreview.map((row, index) => ({ id: index, ...row }))}
                    columns={Object.keys(dataPreview[0]).map((key) => ({
                      field: key,
                      headerName: key,
                      flex: 1,
                      minWidth: 150,
                    }))}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    disableSelectionOnClick
                  />
                </Box>
              ) : (
                <Typography>No data available for preview.</Typography>
              )}
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmImport}
                  disabled={loading}
                  startIcon={<Check />}
                >
                  Confirm Import
                </Button>
              </Box>
            </>
          )}
          {activeStep === 2 && (
            <>
              <Alert severity="success" sx={{ mb: 2 }}>
                <AlertTitle>Success</AlertTitle>
                Data imported successfully!
              </Alert>
              {duplicates.length > 0 && (
                <Box>
                  <Typography variant="body1" gutterBottom>
                    The following records were not inserted because they already exist:
                  </Typography>
                  <List>
                    {duplicates.map((dup, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={`ID Number: ${dup.idNumber}, Name: ${dup.firstName} ${dup.lastName}`} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </>
          )}
        </CardContent>
      </Card>
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      <Dialog
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        aria-labelledby="error-dialog-title"
      >
        <DialogTitle id="error-dialog-title">Error</DialogTitle>
        <DialogContent>
          <Typography>{snackbarMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSnackbarClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ImportDataPage;

import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const SettingsPage = () => {
  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Settings
        </Typography>
        <Typography>
          Placeholder for settings page. Actual settings will be managed through the sidebar navigation.
        </Typography>
      </Box>
    </Container>
  );
};

export default SettingsPage;

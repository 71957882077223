import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Chip, Box } from '@mui/material';
import api from '../api'; // Use the configured Axios instance

// Array of days of the week to be displayed in the dropdown menu
const daysOfWeek = [
  { value: 'Lun', label: 'Lun' },
  { value: 'Mar', label: 'Mar' },
  { value: 'Mié', label: 'Mié' },
  { value: 'Jue', label: 'Jue' },
  { value: 'Vie', label: 'Vie' },
  { value: 'Sáb', label: 'Sáb' },
  { value: 'Dom', label: 'Dom' }
];

const ScheduleModal = ({ open, onClose, onScheduleAddedOrUpdated, editMode, schedule }) => {
  // Initialize the form data state. If in edit mode, pre-fill with existing schedule data
  const [formData, setFormData] = useState({
    days: schedule ? [schedule.day] : [],
    startTime: schedule ? schedule.startTime : '',
    endTime: schedule ? schedule.endTime : ''
  });

  // Initialize the error state to store validation error messages
  const [errors, setErrors] = useState({});

  // Handle change for input fields, updating formData state and clearing any existing errors for the changed field
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' }); // Clear the error when the user changes the field
  };

  // Handle change specifically for the days selection
  const handleDaysChange = (event) => {
    setFormData({ ...formData, days: event.target.value });
  };

  // Validate the form data before submitting
  const validate = () => {
    let tempErrors = {};
    tempErrors.days = formData.days.length ? "" : "This field is required.";
    tempErrors.startTime = formData.startTime ? "" : "This field is required.";
    tempErrors.endTime = formData.endTime ? "" : "This field is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === ""); // Return true if there are no errors
  };

  // Handle the form submission
  const handleSubmit = async () => {
    if (validate()) { // Proceed only if the form data is valid
      try {
        if (editMode) {
          // If in edit mode, update the existing schedule
          const response = await api.put(`/schedules/${schedule._id}`, formData);
          onScheduleAddedOrUpdated(response.data);
        } else {
          // If not in edit mode, create new schedules for each selected day
          const promises = formData.days.map((day) =>
            api.post('/schedules', { day, startTime: formData.startTime, endTime: formData.endTime })
          );
          const results = await Promise.all(promises);
          onScheduleAddedOrUpdated(results.map(result => result.data));
        }
        onClose(); // Close the modal after successful submission
      } catch (error) {
        console.error('Error adding or updating schedule:', error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{editMode ? 'Editar Horario' : 'Agregar Horario'}</DialogTitle>
      <DialogContent>
        {/* Days selection field */}
        <TextField
          select
          label="Días"
          name="days"
          value={formData.days}
          onChange={handleDaysChange}
          fullWidth
          margin="normal"
          error={!!errors.days}
          helperText={errors.days}
          SelectProps={{
            multiple: true,
            renderValue: (selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} sx={{ margin: 0.5 }} />
                ))}
              </Box>
            )
          }}
        >
          {daysOfWeek.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        {/* Start time input field */}
        <TextField
          label="Hora de Inicio"
          name="startTime"
          type="time"
          value={formData.startTime}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={!!errors.startTime}
          helperText={errors.startTime}
          InputLabelProps={{
            shrink: true,
          }}
        />

        {/* End time input field */}
        <TextField
          label="Hora de Fin"
          name="endTime"
          type="time"
          value={formData.endTime}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={!!errors.endTime}
          helperText={errors.endTime}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        {/* Cancel button */}
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        {/* Save button */}
        <Button onClick={handleSubmit} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleModal;

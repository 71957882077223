import React, { useEffect, useState, useCallback } from 'react';
import api from '../api'; // Usar la instancia configurada de Axios
import { Container, Typography, Table, TableHead, TableBody, TableRow, TableCell, Button, Box } from '@mui/material';
import PatientPaymentForm from '../components/PatientPaymentForm';
import { useAuth } from '../contexts/AuthContext'; // Importar el contexto de autenticación

const PatientPaymentPage = () => {
  // Estado para almacenar los pagos de los pacientes
  const [payments, setPayments] = useState([]);
  // Estado para controlar la visibilidad del formulario de pago
  const [isPaymentFormOpen, setPaymentFormOpen] = useState(false);
  // Obtener la clínica del contexto de autenticación
  const { clinic } = useAuth();

  // Función para obtener los pagos de los pacientes desde el servidor
  const fetchPayments = useCallback(async () => {
    try {
      // Realizar una solicitud GET a la API para obtener los pagos de los pacientes de la clínica actual
      const response = await api.get('/patientPayments', {
        params: {
          clinicId: clinic._id
        }
      });
      // Actualizar el estado con los datos de los pagos obtenidos
      setPayments(response.data);
    } catch (error) {
      console.error('Error fetching payments:', error);
    }
  }, [clinic]);

  // useEffect para obtener los pagos cuando el componente se monta o cuando `fetchPayments` cambia
  useEffect(() => {
    if (clinic) {
      fetchPayments();
    }
  }, [clinic, fetchPayments]);

  // Manejar la apertura del formulario de pago
  const handleAddPaymentClick = () => {
    setPaymentFormOpen(true);
  };

  // Manejar el cierre del formulario de pago
  const handlePaymentFormClose = () => {
    setPaymentFormOpen(false);
  };

  // Manejar la actualización de la lista de pagos después de agregar un nuevo pago
  const handlePaymentAdded = () => {
    fetchPayments(); // Volver a obtener los pagos después de agregar uno nuevo
    setPaymentFormOpen(false); // Cerrar el formulario de pago
  };

  return (
    <Container>
      {/* Encabezado de la página con el título y el botón para agregar un nuevo pago */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h2">Patient Payments</Typography>
        <Button variant="contained" color="primary" onClick={handleAddPaymentClick}>
          Add Payment
        </Button>
      </Box>
      
      {/* Tabla para mostrar los pagos de los pacientes */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Patient</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Account</TableCell>
            <TableCell>Clinical History</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment) => (
            payment && payment.patient && payment.clinicalHistory ? (
              <TableRow key={payment._id}>
                <TableCell>{payment.patient.firstName} {payment.patient.lastName}</TableCell>
                <TableCell>{payment.amount}</TableCell>
                <TableCell>{payment.account.name}</TableCell>
                <TableCell>{new Date(payment.clinicalHistory.date).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(payment.date).toLocaleDateString()}</TableCell>
              </TableRow>
            ) : (
              <TableRow key={payment ? payment._id : 'invalid'}>
                <TableCell colSpan={5}>Invalid payment data</TableCell>
              </TableRow>
            )
          ))}
        </TableBody>
      </Table>
      
      {/* Componente del formulario de pago del paciente */}
      {isPaymentFormOpen && (
        <PatientPaymentForm
          open={isPaymentFormOpen}
          onClose={handlePaymentFormClose}
          onPaymentAdded={handlePaymentAdded}
        />
      )}
    </Container>
  );
};

export default PatientPaymentPage;
